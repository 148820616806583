"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var prop_types_1 = require("prop-types");
var react_1 = __importStar(require("react"));
var propTypes = {
    display: prop_types_1.shape({
        grid: prop_types_1.bool,
        gridGap: prop_types_1.string,
        gridMinColumnWidth: prop_types_1.string,
        row: prop_types_1.bool,
        rowGap: prop_types_1.string
    }),
    displayGrid: prop_types_1.bool,
    displayRow: prop_types_1.bool,
    gridGap: prop_types_1.string,
    minColumnWidth: prop_types_1.string,
    rowGap: prop_types_1.string
};
var defaultProps = {
    display: {
        grid: false,
        gridGap: '20px',
        gridMinColumnWidth: '200px',
        row: false,
        rowGap: '20px'
    },
    displayGrid: false,
    displayRow: false,
    gridGap: '20px',
    minColumnWidth: '200px',
    rowGap: '20px'
};
var DisplayHandler = function (props) {
    var displayGrid = props.displayGrid, displayRow = props.displayRow, display = props.display, gridGap = props.gridGap, minColumnWidth = props.minColumnWidth, rowGap = props.rowGap;
    var childSpanRef = react_1.createRef();
    var _a = react_1.useState(null), combo = _a[0], setParentComponent = _a[1];
    var styleParentGrid = function (styleTag, container) {
        if (displayGrid || display.grid) {
            var gap = display.gridGap || gridGap || defaultProps.display.gridGap;
            var column = display.gridMinColumnWidth || minColumnWidth || defaultProps.display.gridMinColumnWidth;
            var styleString = "\n                [data-cont=\"" + container.dataset.cont + "\"] {\n                    display: grid;\n                    grid-gap: " + gap + ";\n                    gap: " + gap + ";\n                    grid-template-columns: repeat(auto-fill, minmax(" + column + ", 1fr));\n                    grid-template-rows: auto;\n                    align-items: stretch;\n                }\n                \n                [data-cont=\"" + container.dataset.cont + "\"] .__infinite-loader,\n                [data-cont=\"" + container.dataset.cont + "\"] .___scroll-renderer-anchor,\n                [data-cont=\"" + container.dataset.cont + "\"] .___list-separator {\n                    grid-column: 1/-1;\n                }\n            ";
            styleTag.innerHTML = styleString;
        }
        else {
            styleTag.innerHTML = '';
        }
    };
    var styleParentRow = function (styleTag, container) {
        if (displayRow || display.row) {
            var gap = display.rowGap || rowGap || defaultProps.display.rowGap;
            var styleString = "\n                [data-cont=\"" + container.dataset.cont + "\"] {\n                    display: flex;\n                    flex-direction: column;\n                }\n                \n                [data-cont=\"" + container.dataset.cont + "\"] > *:not(.__infinite-loader) {\n                    display: block;\n                    flex: 1;\n                    width: 100%;\n                    margin-bottom: " + gap + ";\n                }\n            ";
            styleTag.innerHTML = styleString;
        }
        else {
            styleTag.innerHTML = '';
        }
    };
    var handleDisplayHandlerProps = function (container, style) {
        if (container) {
            if (display.grid || displayGrid) {
                styleParentGrid(style, container);
            }
            else if (display.row || displayRow) {
                styleParentRow(style, container);
            }
        }
    };
    react_1.useEffect(function () {
        if (combo) {
            handleDisplayHandlerProps(combo[0], combo[1]);
        }
    });
    react_1.useEffect(function () {
        var current = childSpanRef.current;
        var container = null;
        var style = null;
        if (current) {
            var id = "__container-" + new Date().getTime();
            container = current.parentNode;
            container.setAttribute('data-cont', id);
            style = document.createElement('STYLE');
            style.id = id;
            document.head.appendChild(style);
            setParentComponent([container, style]);
            handleDisplayHandlerProps(container, style);
        }
        else {
            console.warn('FlatList: it was not possible to get container\'s ref. Styling will not be possible');
        }
        return function () {
            if (style) {
                style.remove();
            }
        };
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null, !combo && react_1.default.createElement("span", { ref: childSpanRef, style: { display: 'none' } })));
};
DisplayHandler.propTypes = propTypes;
DisplayHandler.defaultProps = defaultProps;
exports.default = DisplayHandler;
