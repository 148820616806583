"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var prop_types_1 = require("prop-types");
var react_1 = __importStar(require("react"));
var isType_1 = require("../___utils/isType");
var uiFunctions_1 = require("./uiFunctions");
var ScrollToTopButton = function (props) {
    var anchor = react_1.createRef();
    var button = props.button, position = props.position, padding = props.padding, offset = props.offset, scrollingContainer = props.scrollingContainer;
    var btn = isType_1.isFunction(button) ? button() : button;
    var _a = react_1.useState(false), mounted = _a[0], setMounted = _a[1];
    react_1.useEffect(function () {
        var buttonElement = anchor.current.nextElementSibling;
        var container = anchor.current.parentNode;
        var scrollContainer = scrollingContainer.current;
        var containerStyle = getComputedStyle(container);
        container.style.overflow = 'hidden';
        container.style.position = ['absolute', 'fixed', 'relative'].includes(containerStyle.overflow)
            ? containerStyle.overflow : 'relative';
        scrollContainer.style.overflow = 'auto';
        scrollContainer.style.padding = containerStyle.padding;
        scrollContainer.style.height = '100%';
        container.style.padding = '0';
        var positionBtn = uiFunctions_1.btnPosition(scrollContainer, buttonElement);
        var pos = position.split(' ');
        var updateBtnPosition = function () { return positionBtn(pos[0], pos[1], padding, offset); };
        window.addEventListener('resize', updateBtnPosition);
        scrollContainer.addEventListener('scroll', updateBtnPosition);
        buttonElement.addEventListener('click', function () {
            scrollContainer.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        });
        setTimeout(function () { return updateBtnPosition(); }, 250);
        setMounted(true);
        return function () {
            container.style.removeProperty('overflow');
            container.style.removeProperty('position');
            container.style.removeProperty('padding');
            window.removeEventListener('resize', updateBtnPosition);
        };
    }, []);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !mounted && react_1.default.createElement("span", { ref: anchor, style: { display: 'none' } }),
        button ? btn : react_1.default.createElement("button", { type: "button" }, "To Top")));
};
ScrollToTopButton.propTypes = {
    scrollingContainer: prop_types_1.shape({ current: prop_types_1.oneOf([prop_types_1.element, prop_types_1.node]) }).isRequired,
    button: prop_types_1.oneOfType([prop_types_1.node, prop_types_1.element, prop_types_1.func]),
    position: prop_types_1.oneOf(['top right', 'top left', 'bottom right', 'bottom left']),
    padding: prop_types_1.number,
    offset: prop_types_1.number
};
ScrollToTopButton.defaultProps = {
    button: null,
    padding: 20,
    offset: 50,
    position: 'bottom right'
};
exports.default = ScrollToTopButton;
