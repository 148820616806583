import { token, conection } from './Config.js';

export const apiLogin = (user, pwd) => {
    return fetch(`${conection}/cursos/api/user/login`,
    {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
        {
            email: user,
            password: pwd,
        }),
    })
}

export const apiGetUsers = (search, typeSearch, startDate, endDate) => {
    return fetch(`${conection}/cursos/api/panel/users?filter=${search}&orderBy=${typeSearch}&fini=${startDate}&ffin=${endDate}&cache=${23}`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization' : `${token()}`,
        },
    })
}

export const apiGetTotalUsers = () => {
    return fetch(`${conection}/cursos/api/panel/count`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization' : `${token()}`,
        },
    })
}

export const apiSendVerificationCode = (userId) => {
    return fetch(`${conection}/cursos/api/user/reenviar?id=${userId}`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization' : `${token()}`,
        },
    })
}

export const apiUpdateUserType = (userId, userType) => {
    return fetch(`${conection}/cursos/api/panel/admin/update`,
    {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization' : `${token()}`,
        },
        body: JSON.stringify(
        {
            id: userId,
            type: userType,
        }),
    })
}

export const apiGetAdmins = () => {
    return fetch(`${conection}/cursos/api/panel/admin`,
    {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization' : `${token()}`,
        },
    })
}