import React, { useState, useRef, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { apiLogin } from "../components/ServicesApi";
import { token, setToken, userData, setUserData } from "../components/Config";
import Styles from "../components/Styles";
import WzColors from "../components/WzColors";
import WzResources from "../components/WzResources";

const getPrettyDate = (date) => {
    return date < 10 ? '0' + date : '' + date; // ('' + month) for string result
}

const letsLogin = (navigate, setRefreshing, user, pwd) => {
    setRefreshing('Iniciando sesión...')
    
    apiLogin(user, pwd).then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('Login exitoso:', response)
        if(response != null){
            if(response.status){
                var day = new Date().getDate()
                var month = new Date().getMonth()+1
                var year = new Date().getFullYear()
                var today = getPrettyDate(day) + '-' + getPrettyDate(month) + '-' + year
                if(response.data.admin == 1){
                    //PRINCIPAL
                    setToken(response.data.token)
                    setUserData(JSON.stringify(response.data))

                    navigate(`/Inicio/1/0/nombre/*/${today}/${today}`, { replace: false, state: {} });
                    //console.log('Inicié PRINCIPAL')
                }else if(response.data.admin == 2){
                    //Colaborador
                    setToken(response.data.token)
                    setUserData(JSON.stringify(response.data))

                    navigate(`/Inicio/1/0/nombre/*/${today}/${today}`, { replace: false, state: {} });
                    //console.log('Inicié COLABORADOR')
                }else{
                    Swal.fire({
                        icon: 'info',
                        title: 'Tipo de usuario no permitido',
                        text: 'Este usuario no tiene acceso',
                        confirmButtonText:
                            'Aceptar',
                    })
                    //console.log('Tipo de usuario raro que no existe')
                }
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }
        setRefreshing('')
    });
}


function Login(props) {

    const navigate = useNavigate();

    const {
        wzContainer,
        wzContainerFullCentered,
        wzCardContainerRounded,
        wzWaitingContianer,

        wzTextHuge,
        wzTextNormal,
        wzTextMedium,
        wzTextSmall,
        wzTextMini,

        wzLoginImage,
        
        wzTextInput,
        
        wzButtonBottomAction,

        wzVerticalSpace1,
        wzPadding4,

        wzBgPrimary,
    } = Styles();

    

    useEffect(() => {
        scrollToTheTop()

        //console.log('USERDATA: ' + JSON.stringify(userData()))
        if(token() != null && token() != ''){
            //console.log('USERDATA WITH TOKEN: ' + JSON.stringify(userData()))
            var day = new Date().getDate()
            var month = new Date().getMonth()+1
            var year = new Date().getFullYear()

            var today = getPrettyDate(day) + '-' + getPrettyDate(month) + '-' + year
            navigate(`/Inicio/1/0/nombre/*/${today}/${today}`, { replace: true, state: {} });
        }
    }, [])

    const scrollToTheTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const [user, setUser] = useState('');
    
    const [pwd, setPwd] = useState('');
    const pwdRef = useRef(null);

    const [refreshing, setRefreshing] = useState(false);

    

    return (
        <Grid className={wzContainer}>

            <Grid className={wzContainerFullCentered} style={{ display: 'flex' }}>
                <Grid className={`${wzCardContainerRounded} ${wzPadding4}`} style={{ position: 'relative', paddingTop: 0 }}>
                    <Grid className={wzVerticalSpace1}/>
                    <Grid container style={{alignItems: 'center', justifyContent: 'center'}}>
                        <img src={WzResources.pngLogo} alt="Foto perfil" className={wzLoginImage} style={{ boxShadow: '0px 0px 7px 0px ' + WzColors.tPrimary + '0)' }}/>
                    </Grid>
                    <Grid className={wzVerticalSpace1}/>
                    <Grid className={wzVerticalSpace1}/>

                    <p className={wzTextSmall} style={{ marginTop: 0 }}>Correo electrónico:</p>
                    <Grid container>
                        <TextField
                            className={wzTextInput}
                            style={{ width: '100%' }}
                            label={"ejemplo@ejemplo.com"}
                            variant="outlined"
                            type="default"
                            id="custom-css-outlined-input"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                            inputProps={{
                                className: wzTextNormal,
                                onKeyPress: event => {
                                    const { key } = event;
                                    //console.log(key);
                                    if (key === "Enter") {
                                        pwdRef.current.focus();
                                    }
                                }
                            }}
                        />
                    </Grid>

                    <p className={wzTextSmall}>Contraseña:</p>
                    <Grid container>
                        <TextField
                            className={wzTextInput}
                            style={{ width: '100%' }}
                            label={"●●●●●●●●"}
                            variant="outlined"
                            type="password"
                            autoComplete="current-password"
                            id="custom-css-outlined-input"
                            value={pwd}
                            onChange={(e) => {setPwd(e.target.value)}}
                            inputProps={{
                                className: wzTextNormal,
                                onKeyPress: event => {
                                    const { key } = event;
                                    //console.log(key);
                                    if (key === "Enter") {
                                        letsLogin(navigate, setRefreshing, user, pwd)
                                    }
                                }
                            }}
                            inputRef={pwdRef}
                        />
                    </Grid>
                    <Button
                        onClick={() => {
                            letsLogin(navigate, setRefreshing, user, pwd)
                        }}
                        className={`${wzButtonBottomAction} ${wzTextMedium} ${wzBgPrimary}`}
                        fullWidth={true}
                        style={{ textTransform: 'none', color: 'white', marginBottom: 0, marginTop: 0, alignItems: 'center' }}
                    >
                        Entrar
                    </Button>
                </Grid>
            </Grid>

            <Grid style={{position: 'absolute', bottom: '2vh', left: '2vh'}}>
                <p className={wzTextMini} style={{color: WzColors.tPrimary + '0.7)', fontStyle: 'italic'}}>v2.0.02-2022</p>
            </Grid>

            {
                refreshing != '' ?
                    <Grid className={wzWaitingContianer}>
                        <p className={wzTextHuge} style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>{refreshing}</p>
                    </Grid>
                :
                    null
            }
        </Grid>
    );
}

export default Login;