import React, { useState, useEffect } from 'react';

import { useNavigate } from "react-router-dom";

import Swal from 'sweetalert2';

import FlatList from 'flatlist-react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import esLocale from 'date-fns/locale/es';

import FaceIcon from '@mui/icons-material/Face';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { apiGetAdmins, apiUpdateUserType } from "../components/ServicesApi";
import { token, setToken, userData, setUserData } from "../components/Config";
import Styles from "../components/Styles";
import WzColors from "../components/WzColors";

esLocale.options.weekStartsOn = 0

const updateUserType = (setRefreshing, userData, userType, doGetAdmins) => {
    setRefreshing('Actualizando tipo de usuario...')
    
    apiUpdateUserType(userData.user_id, userType).then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('Total de usuarios:', response)
        if(response != null){
            if(response.status){
                Swal.fire({
                    icon: 'success',
                    title: 'Usuario actualizado correctamente',
                    text: `Se cambio el tipo de usuario para: ${userData.nombre}`,
                    confirmButtonText:
                        'Aceptar',
                })
                doGetAdmins()
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }else{
            setToken('')
            setUserData('{}')
            useNavigate().navigate(`/`, { replace: true, state: {} });
        }
        setRefreshing('')
    });
}

const getAdmins = (setRefreshing, setAdminsData) => {
    setRefreshing('Obteniendo administardores...')
    
    apiGetAdmins().then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('Total de usuarios:', response)
        if(response != null){
            if(response.status){
                setAdminsData(response.data)
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }else{
            setToken('')
            setUserData('{}')
            useNavigate().navigate(`/`, { replace: true, state: {} });
        }
        setRefreshing('')
    });
}

function Admins(props) {

    const navigate = useNavigate();

    const {
        wzContainer,
        wzCardContainerRounded,
        wzWaitingContianer,
        wzContainerResponsiveWidth60,

        wzTextHuge,
        wzTextNormalHeader,
        wzTextNormal,
        
        wzButtonIcon,
        
        wzButtonBottom,
        wzButtonBack,
        wzButtonCirclePadding,


        wzVerticalSpace1,
        wzPadding2,
        wzPadding1,

        wzBgPrimary,
        wzBgSecondary,
    } = Styles();

    

    useEffect(() => {
        scrollToTheTop()

        if(token() != null && token() != ''){
            doGetAdmins()
        }else{
            navigate(`/`, { replace: true, state: {} });
        }
    }, [])

    const scrollToTheTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const [adminsData, setAdminsData] = useState([]);

    const [refreshing, setRefreshing] = useState(false);

    

    const doGetAdmins = () => {
        getAdmins(setRefreshing, setAdminsData)
    }

    const doUpdateUserType = (userData, userType) => {
        updateUserType(setRefreshing, userData, userType, doGetAdmins)
    }

    return (
        <Grid className={wzContainer}>
            <Grid className={wzPadding2}>
                <Grid container justifyContent="center">
                    <Grid className={`${wzCardContainerRounded} ${wzPadding1} ${wzContainerResponsiveWidth60} ${wzBgPrimary}`} style={{ position: 'relative', borderWidth: 0 }}>
                        <p className={wzTextNormalHeader} style={{ color: 'white', textAlign: 'center', marginTop: 0, marginBottom: 0, fontWeight: '600' }}>- ADMINISTRADORES -</p>
                    </Grid>
                </Grid>

                <Grid className={wzVerticalSpace1}/>
                <Grid className={wzVerticalSpace1}/>

                <p className={wzTextNormal} style={{ color: WzColors.hexSecondary, marginTop: 0, textAlign: 'center' }}>Administradores encontrados: <span style={{ fontWeight: 'bold' }}>{adminsData.length}</span></p>
                <Grid className={`${wzCardContainerRounded}`} style={{ background: WzColors.hexResSecondary, position: 'relative', padding: 0, borderColor: WzColors.hexDesSecondary }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ color: 'white' }}>Nombre</TableCell>
                                    <TableCell style={{ color: 'white' }} align="right">País</TableCell>
                                    <TableCell style={{ color: 'white' }} align="right">email</TableCell>
                                    <TableCell style={{ color: 'white' }} align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FlatList
                                    list={adminsData}
                                    renderItem={(item, idx) => {
                                        //console.log('POS EL TRATAMIENTO: ' + JSON.stringify(item))
                                        return(
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell style={{ color: 'white' }} component="th" scope="row">
                                                    {item.nombre}
                                                </TableCell>
                                                <TableCell style={{ color: 'white' }} align="right">{item.pais}</TableCell>
                                                <TableCell style={{ color: 'white' }} align="right">{item.email}</TableCell>
                                                <TableCell style={{ color: 'white' }} align="right">
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <Tooltip title="Administrador principal">
                                                            <IconButton
                                                                className={`${wzButtonCirclePadding} ${parseInt(item.admin) == 1 ? wzBgPrimary : wzBgSecondary}`}
                                                                onClick={() => {
                                                                    if(userData().user_id != item.user_id){
                                                                        if(parseInt(item.admin) != 1){
                                                                            doUpdateUserType(item, 1)
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <AdminPanelSettingsIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Administrador colaborador">
                                                            <IconButton
                                                                className={`${wzButtonCirclePadding} ${parseInt(item.admin) == 2 ? wzBgPrimary : wzBgSecondary}`}
                                                                style={{ marginLeft: '0px !important', marginRight: '0px !important' }}
                                                                onClick={() => {
                                                                    if(userData().user_id != item.user_id){
                                                                        if(parseInt(item.admin) != 2){
                                                                            doUpdateUserType(item, 2)
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <AssignmentIndIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Usuario normal">
                                                            <IconButton
                                                                className={`${wzButtonCirclePadding} ${parseInt(item.admin) == 0 ? wzBgPrimary : wzBgSecondary}`}
                                                                style={{ marginLeft: '0px !important', marginRight: '0px !important' }}
                                                                onClick={() => {
                                                                    if(userData().user_id != item.user_id){
                                                                        if(parseInt(item.admin) != 0){
                                                                            doUpdateUserType(item, 0)
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <FaceIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }}
                                    renderWhenEmpty={() => <p style={{ color: WzColors.hexPrimary }}>- No se encontraron resultados -</p>}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>

            <Grid container className={wzButtonBottom} style={{ width: '100%', boxShadow: '0px 0px 7px 0px ' + WzColors.tPrimary + '0)', zIndex: 20, alignItems: 'center', justifyContent: 'space-between', textAlign: 'center'}}>
                <Tooltip title="Regresar">
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate(-1)
                        }}
                        color="primary"
                        className={`${wzButtonBack} ${wzBgPrimary}`}
                        fullWidth={false}
                    >
                        <ArrowBackIosIcon className={wzButtonIcon} style={{ color: 'white' }} />
                    </Button>
                </Tooltip>
            </Grid>

            {
                refreshing != '' ?
                    <Grid className={wzWaitingContianer}>
                        <p className={wzTextHuge} style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>{refreshing}</p>
                    </Grid>
                :
                    null
            }
        </Grid>
    );
}

export default Admins;