import React, { Component } from "react";
import {
    //HashRouter as Router,
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import Login from "./screens/Login";

import "./components/RequiredStyles.css";


import Home from "./screens/Home";

import Admins from "./screens/Admins";

function App() {

    return (
        <div>
            <Router history={Router}>
                <Routes>
                    <Route exact path="/" element={<Login/>}/>

                    {/*<Route path="/Home/:Token/:userTypePro">*/}
                    <Route exact path="/Inicio/:preAlways/:preWorld/:preTypeSearch/:preSearch/:preStartDate/:preEndDate" element={<Home/>}/>
                    <Route exact path="/Administradores" element={<Admins/>}/>

                    <Route path="*" element={<Login/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;