/*

Marca:
primary
auxPrimary
secondary

Acción/Positiva:
primary
auxPrimary
action

Editar:
UpdatePrimary
UpdateSecondary
UpdateConfirmation

Eliminar/Cancelar:
AuxPrimary
Cancel

Botones o contenido Extra:
extra
auxExtra

*/

const WzColors = {

    hexPrimary: '#4276FD',
    tPrimary: 'rgba(66, 118, 253, ',
    hexResPrimary: '#3562D9',
    tResPrimary: 'rgba(53, 98, 217, ',
    hexDesPrimary: '#9FBAFF',
    tDesPrimary: 'rgba(159, 186, 255, ',

    hexSecondary: '#001F71',
    tSecondary: 'rgba(0, 31, 113, ',
    hexResSecondary: '#061438',
    tResSecondary: 'rgba(6, 20, 56,',
    hexDesSecondary: '#4B5D8D',
    tDesSecondary: 'rgba(75, 93, 141,',

    hexAuxPrimary: '#AF79AF',
    tAuxPrimary: 'rgba(175, 121, 175,',
    hexResAuxPrimary: '#704475',
    tResAuxPrimary: 'rgba(112, 68, 117,',
    hexDesAuxPrimary: '#D6BBD8',
    tDesAuxPrimary: 'rgba(214, 187, 216,',

    hexExtra: '#3BB6BF',
    tExtra: 'rgba(59, 182, 191,',
    hexResExtra: '#22A0A3',
    tResExtra: 'rgba(34, 160, 163,',
    hexDesExtra: '#63C5D0',
    tDesExtra: 'rgba(110, 197, 208,',

    
    hexAuxExtra: '#17EAD9',
    tAuxExtra: 'rgba(23, 234, 217,',
    hexResAuxExtra: '#18A59A',
    tResAuxExtra: 'rgba(24, 156, 154,',
    hexDesAuxExtra: '#ADFAF4',
    tDesAuxExtra: 'rgba(173, 250, 244,',
    


    hexAction: '#34B086',
    tAction: 'rgba(52, 176, 134,',
    hexResAction: '#248461',
    tResAction: 'rgba(36, 132, 79,',
    hexDesAction: '#7DC5A8',
    tDesAction: 'rgba(125, 197, 168,',



    hexUpdatePrimary: '#FAB95A',
    tUpdatePrimary: 'rgba(250, 185, 90,',
    hexResUpdatePrimary: '#DF9531',
    tResUpdatePrimary: 'rgba(124, 124, 124,',
    hexDesUpdatePrimary: '#F2C58A',
    tDesUpdatePrimary: 'rgba(242, 297, 238,',

    hexUpdateSecondary: '#FCCF50',
    tUpdateSecondary: 'rgba(252, 207, 80,',
    hexResUpdateSecondary: '#E2AF2C',
    tResUpdateSecondary: 'rgba(226, 175, 44,',
    hexDesUpdateSecondary: '#F5D589',
    tDesUpdateSecondary: 'rgba(245, 213, 137,',

    hexUpdateConfirmation: '#C7D543',
    tUpdateConfirmation: 'rgba(199, 213, 67',
    hexResUpdateConfirmation: '#AAAE36',
    tResUpdateConfirmation: 'rgba(170, 179, 54,',
    hexDesUpdateConfirmation: '#DBD98D',
    tDesUpdateConfirmation: 'rgba(219, 217, 141,',

    

    hexCancel: '#E84A73',
    tCancel: 'rgba(232, 74, 115,',
    hexResCancel: '#BC2F61',
    tResCancel: 'rgba(188, 47, 97,',
    hexDesCancel: '#E97096',
    tDesCancel: 'rgba(233, 112, 150,',

   
    //grnPrimary: [ '', '' ],
    //grPrimary: 'linear-gradient(90deg, rgba(222, 232, 255, 1) 15%, rgba(132, 0, 255, 1) 40%, rgba(222, 232, 255, 1) 60%, rgba(132, 0, 255, 1) 85%),',
}

export default WzColors