"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var typesMap = {
    array: 'ARRAY',
    boolean: 'BOOLEAN',
    function: 'FUNCTION',
    map: 'MAP',
    null: 'NULL',
    number: 'NUMBER',
    object: 'OBJECT',
    set: 'SET',
    string: 'STRING',
    symbol: 'SYMBOL',
    undefined: 'UNDEFINED',
    weakMap: 'WEAK_MAP',
    weakSet: 'WEAK_SET'
};
exports.types = Object.values(typesMap)
    .reduce(function (obj, type) {
    obj[type] = type;
    return obj;
}, {});
var getType = function (x) {
    var type = typeof x;
    switch (type) {
        case 'number':
        case 'string':
        case 'boolean':
        case 'undefined':
        case 'symbol':
        case 'function':
            return typesMap[type];
        default:
            return x === null ? typesMap.null
                : (x instanceof Set) ? typesMap.set
                    : (x instanceof WeakSet) ? typesMap.weakSet
                        : (x instanceof Map) ? typesMap.map
                            : (x instanceof WeakMap) ? typesMap.weakMap
                                : Array.isArray(x) ? typesMap.array
                                    : typesMap.object; // otherwise it is an object
    }
};
exports.default = getType;
