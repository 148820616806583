//export const token = localStorage.getItem('token');
export const token = () => {
    return localStorage.getItem('token');
}

export const setToken = (token) => {
    return localStorage.setItem('token', token);
}

//export var userType = localStorage.getItem('userType');

export const userData = () => {
    return JSON.parse(localStorage.getItem('userData'));
}

export const setUserData = (userData) => {
    return localStorage.setItem('userData', userData);
}

//export const conection = 'http://localhost:9906/api';
export const conection = 'https://cursos.labibliatienerazon.com';
//export const conection = 'https://b6ad-2806-2f0-51c0-589e-5952-b043-85fc-184f.ngrok.io/api';