"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var getType_1 = __importStar(require("./getType"));
exports.isBoolean = function (x) { return getType_1.default(x) === getType_1.types.BOOLEAN; };
exports.isNumber = function (x) { return getType_1.default(Number(x)) === getType_1.types.NUMBER && !isNaN(Number(x)); };
exports.isNumeric = function (x) { return isFinite(x) && exports.isNumber(x); };
exports.isObject = function (x) { return getType_1.default(x) === getType_1.types.OBJECT; };
exports.isObjectLiteral = function (x) { return exports.isObject(x) && (x.constructor === Object); };
exports.isString = function (x) { return getType_1.default(x) === getType_1.types.STRING || x instanceof String; };
exports.isArray = function (x) { return getType_1.default(x) === getType_1.types.ARRAY; };
exports.isSet = function (x) { return getType_1.default(x) === getType_1.types.SET; };
exports.isMap = function (x) { return getType_1.default(x) === getType_1.types.MAP; };
exports.isNil = function (x) { return (x === null || getType_1.default(x) === getType_1.types.UNDEFINED); };
exports.isEmpty = function (x) { return (((exports.isString(x) || exports.isArray(x)) && x.length === 0)
    || (exports.isObject(x) && Object.keys(x).length === 0)
    || (getType_1.default(x) === getType_1.types.MAP && x.size === 0)
    || (getType_1.default(x) === getType_1.types.SET && x.size === 0)
    || (getType_1.default(x) === getType_1.types.NUMBER && isNaN(x))); };
exports.isNilOrEmpty = function (x) { return (exports.isNil(x) || exports.isEmpty(x)); };
exports.isFunction = function (x) { return getType_1.default(x) === getType_1.types.FUNCTION; };
exports.default = {
    isArray: exports.isArray,
    isFunction: exports.isFunction,
    isNil: exports.isNil,
    isEmpty: exports.isEmpty,
    isNilOrEmpty: exports.isNilOrEmpty,
    isNumber: exports.isNumber,
    isObject: exports.isObject,
    isString: exports.isString,
    isSet: exports.isSet,
    isMap: exports.isMap
};
