"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var getType_1 = __importStar(require("./getType"));
exports.default = (function (list) {
    var listType = getType_1.default(list);
    switch (listType) {
        case getType_1.types.ARRAY:
            return list;
        case getType_1.types.OBJECT:
            return Object.values(list);
        case getType_1.types.SET:
            return Array.from(list);
        case getType_1.types.MAP:
            return Array.from(list.values());
        default:
            return [];
    }
});
