import React from "react";

import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import WzColors from "./WzColors";

const wzRoyalLargeSize = 14

const wzScaleLargeMini = 0.8
const wzScaleLargeSmall = 0.86
const wzScaleLargeMedium = 0.95
const wzScaleLargeNormalHeader = 1.29
const wzScaleLargeSubtitle = 1.79
const wzScaleLargeTitle = 2.14
const wzScaleLargeHuge = 2.64

const wzRoyalTabletSize = 13.7

const wzScaleTabletMini = 0.8
const wzScaleTabletSmall = 0.82
const wzScaleTabletMedium = 0.88
const wzScaleTabletNormalHeader = 1.17
const wzScaleTabletSubtitle = 1.46
const wzScaleTabletTitle = 1.72
const wzScaleTabletHuge = 2.19


const wzRoyalPhoneSize = 12.5

const wzScalePhoneMini = 0.8
const wzScalePhoneSmall = 0.82
const wzScalePhoneMedium = 0.88
const wzScalePhoneNormalHeader = 1.12
const wzScalePhoneSubtitle = 1.28
const wzScalePhoneTitle = 1.6
const wzScalePhoneHuge = 2

const theme = createTheme();
// returns the style
function Styles (){
    const useStyles = makeStyles(() => ({

        wzContainer: {
            width: '100%',
            height: '100%',
            position: 'fixed',
            overflow: 'auto'
        },
        wzContainerFull: {
            display: 'flex',
            background: 'white',
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            top: '0'
        },
        wzContainerFullCentered:{
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            top: '0'
        },
        wzWaitingContianer:{
            background: 'rgba(0, 0, 0, 0.65)',
            display: 'flex',
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 3000
        },
        wzCardContainerRounded:{
            background: 'white',
            overflow: 'hidden',

            [theme.breakpoints.up('xs')]: {
                boxShadow: '0.5px 2px 4px 0.5px ' + WzColors.tExtra + ' 0.3)',
                padding: '1.1em',
                borderRadius: '1.2em',
                borderColor: WzColors.hexExtra,
                borderWidth: '0.15em',
                borderStyle: 'solid',
            },
            [theme.breakpoints.up('sm')]: {
                boxShadow: '1px 3px 6px 1px ' + WzColors.tExtra + ' 0.3)',
                padding: '1.3em',
                borderRadius: '1.6em',
                borderColor: WzColors.hexExtra,
                borderWidth: '0.2em',
                borderStyle: 'solid',
            },
            [theme.breakpoints.up('md')]: {
                boxShadow: '2px 4px 8px 2px ' + WzColors.tExtra + ' 0.3)',
                padding: '1.5em',
                borderRadius: '2em',
                borderColor: WzColors.hexExtra,
                borderWidth: '0.3em',
                borderStyle: 'solid',
            },
        },
        wzContainerResponsiveWidth50:{
            [theme.breakpoints.up('xs')]: {
                width: '100% !important',
            },
            [theme.breakpoints.up('sm')]: {
                width: '75% !important',
            },
            [theme.breakpoints.up('md')]: {
                width: '50% !important',
            },
        },
        wzContainerResponsiveWidth60:{
            [theme.breakpoints.up('xs')]: {
                width: '100% !important',
            },
            [theme.breakpoints.up('sm')]: {
                width: '80% !important',
            },
            [theme.breakpoints.up('md')]: {
                width: '60% !important',
            },
        },
        wzFormBackgroundContainer:{
            background: 'rgba(0, 0, 0, 0.6)',
            opacity: 1,
            transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            outline: 0,
            alignItems: 'center',
            justifyContent: 'center',
            background: 'white',
            position: 'fixed',
            left: '0',
            right: '0',
            bottom: '0',
            top: '0'
        },
        wzFormContainer:{
            display: 'grid',
            flexDirection: 'column',
            background: 'white',
            borderColor: WzColors.hexExtra,
            borderStyle: 'solid',
            zIndex: 10,
            marginLeft: 'auto',
            marginRight: 'auto',
            overflow: 'hidden',
            position: 'relative',

            [theme.breakpoints.up('xs')]: {
                borderWidth: wzRoyalPhoneSize * 0.15,
                borderRadius: wzRoyalPhoneSize * 2,
                width: wzRoyalPhoneSize * 23,
                marginTop: wzRoyalPhoneSize * 9,
                marginBottom: wzRoyalPhoneSize * 9,
                //maxHeight: `calc(100% - ${wzRoyalPhoneSize * 18}px)`,
            },
            [theme.breakpoints.up('sm')]: {
                borderWidth: wzRoyalTabletSize * 0.15,
                borderRadius: wzRoyalTabletSize * 2,
                width: wzRoyalTabletSize * 35,
                marginTop: wzRoyalTabletSize * 7,
                marginBottom: wzRoyalTabletSize * 7,
                //maxHeight: `calc(100% - ${wzRoyalTabletSize * 14}px)`,
            },
            [theme.breakpoints.up('md')]: {
                borderWidth: wzRoyalLargeSize * 0.15,
                borderRadius: wzRoyalLargeSize * 2,
                width: wzRoyalLargeSize * 38,
                marginTop: wzRoyalLargeSize * 6,
                marginBottom: wzRoyalLargeSize * 6,
                //maxHeight: `calc(100% - ${wzRoyalLargeSize * 12}px)`,
            },
        },
        wzFormHeaderContainer:{
            flex: '0 0 auto',
            background: WzColors.hexPrimary,
            width: '100%',
            [theme.breakpoints.up('xs')]: {
                paddingLeft: wzRoyalPhoneSize * 0.15,
                paddingRight: wzRoyalPhoneSize * 0.15,
            },
            [theme.breakpoints.up('sm')]: {
                paddingLeft: wzRoyalTabletSize * 0.15,
                paddingRight: wzRoyalTabletSize * 0.15,
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: wzRoyalLargeSize * 0.15,
                paddingRight: wzRoyalLargeSize * 0.15,
            },
        },
        wzFormContentContainer:{
            flex: '1 1 auto',
            overflowY: 'auto',
            display: 'flex',
            background: 'white',
            [theme.breakpoints.up('xs')]: {
            },
            [theme.breakpoints.up('sm')]: {
            },
            [theme.breakpoints.up('md')]: {
            },
        },
        wzGroupContainer:{
            position: 'relative',
            background: 'white',
            width: '100%',
            overflow: 'hidden',

            [theme.breakpoints.up('xs')]: {
                marginTop: wzRoyalPhoneSize * -2,
                paddingTop: wzRoyalPhoneSize * 3,
                padding: wzRoyalPhoneSize * 1,
                borderBottomRightRadius: wzRoyalPhoneSize * 1,
                borderBottomLeftRadius: wzRoyalPhoneSize * 1,
                boxShadow: '0px 7px 3px 0px rgba(0, 0, 0, 0.1)',
            },
            [theme.breakpoints.up('sm')]: {
                marginTop: wzRoyalTabletSize * -2,
                paddingTop: wzRoyalTabletSize * 3,
                padding: wzRoyalTabletSize * 1,
                borderBottomRightRadius: wzRoyalTabletSize * 1,
                borderBottomLeftRadius: wzRoyalTabletSize * 1,
                boxShadow: '0px 9px 3px 0px rgba(0, 0, 0, 0.1)',
            },
            [theme.breakpoints.up('md')]: {
                marginTop: wzRoyalLargeSize * -2,
                paddingTop: wzRoyalLargeSize * 3,
                padding: wzRoyalLargeSize * 1,
                borderBottomRightRadius: wzRoyalLargeSize * 1,
                borderBottomLeftRadius: wzRoyalLargeSize * 1,
                boxShadow: '0px 13px 5px 0px rgba(0, 0, 0, 0.1)',
            },
        },



        wzLoginImage: {
            background: '',
            
            objectFit: 'contain',
            alignSelf: 'center',
            
            /*[theme.breakpoints.up('xs')]: {
                height: '5em',
                width: '9em',
            },
            [theme.breakpoints.up('sm')]: {
                height: '6em',
                width: '10em',
            },
            [theme.breakpoints.up('md')]: {
                height: '8em',
                width: '12em',
            },*/

            boxShadow: '0px 0px 7px 0px ' + WzColors.tPrimary + '0.79)',
            [theme.breakpoints.up('xs')]: {
                height: wzRoyalPhoneSize * 6,
                width: wzRoyalPhoneSize * 6,
            },
            [theme.breakpoints.up('sm')]: {
                height: wzRoyalTabletSize * 7,
                width: wzRoyalTabletSize * 7,
            },
            [theme.breakpoints.up('md')]: {
                height: wzRoyalLargeSize * 8,
                width: wzRoyalLargeSize * 8,
            },
        },
        wzProfileImage: {
            background: '',
            
            objectFit: 'contain',
            alignSelf: 'center',
            borderRadius: 5000,

            boxShadow: '0px 0px 7px 0px ' + WzColors.tPrimary + '0.79)',
            [theme.breakpoints.up('xs')]: {
                height: wzRoyalPhoneSize * 8,
                width: wzRoyalPhoneSize * 8,
            },
            [theme.breakpoints.up('sm')]: {
                height: wzRoyalTabletSize * 10,
                width: wzRoyalTabletSize * 10,
            },
            [theme.breakpoints.up('md')]: {
                height: wzRoyalLargeSize * 12,
                width: wzRoyalLargeSize * 12,
            },
        },




        wzTextInput: {
            [theme.breakpoints.up('xs')]: {
                width: '90%',
            },
            [theme.breakpoints.up('sm')]: {
                width: '60%',
            },
            [theme.breakpoints.up('md')]: {
                width: '40%',
            },
            '& label.Mui-focused': {
                color: WzColors.tExtra + ' 0.5)',
            },
            '& .MuiInputLabel-root': {
                color: WzColors.tExtra + ' 0.5)',
            },
            '& .MuiInputBase-root': {
                color: WzColors.hexPrimary,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: WzColors.hexPrimary + ' !important', //Morado fuerte
                    borderRadius: '1em',
                    borderWidth: '0.17em',
                },
                '&:hover fieldset': {
                    borderColor: WzColors.hexPrimary + ' !important', //Morado fuerte
                },
                '&.Mui-focused fieldset': {
                    borderColor: WzColors.hexPrimary + ' !important', //Morado fuerte
                },
            },
        },



        wzButton: {
            //boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            /*'&:hover': {
                background: 'rgba(0,0,0,0)',
                boxShadow: '0px 0px 0px 0px rgba(0,0,0,0)',
            },*/
            [theme.breakpoints.up('xs')]: {
                paddingLeft: wzRoyalPhoneSize * 1 + ' !important',
                paddingRight: wzRoyalPhoneSize * 1 + ' !important',
            },
            [theme.breakpoints.up('sm')]: {
                paddingLeft: wzRoyalTabletSize * 1 + ' !important',
                paddingRight: wzRoyalTabletSize * 1 + ' !important',
            },
            [theme.breakpoints.up('md')]: {
                paddingLeft: wzRoyalLargeSize * 1 + ' !important',
                paddingRight: wzRoyalLargeSize * 1 + ' !important',
            },
        },
        wzButtonBottomAction: {
            position: 'absolute !important',
            bottom: 0 + ' !important',
            left: 0 + ' !important',
            right: 0 + ' !important',

            borderRadius: '0px !important',
            [theme.breakpoints.up('xs')]: {
                padding: wzRoyalPhoneSize * 1 + ' !important',
            },
            [theme.breakpoints.up('sm')]: {
                padding: wzRoyalTabletSize * 1 + ' !important',
            },
            [theme.breakpoints.up('md')]: {
                padding: wzRoyalLargeSize * 1 + ' !important',
            },
        },
        wzButtonBottom:{
            boxShadow: '0px 0px 10px 0px ' + WzColors.tPrimary + '0.3)',
            position: 'fixed' + ' !important',
            
            bottom: 0 + 'px !important',
            width: '98.7%' + ' !important',
            [theme.breakpoints.up('xs')]: {
                paddingBottom: wzRoyalPhoneSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('sm')]: {
                paddingBottom: wzRoyalTabletSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('md')]: {
                paddingBottom: wzRoyalLargeSize * 1 + 'px !important',
            },
        },
        wzButtonBack:{
            borderRadius: 0 + 'px !important',
            [theme.breakpoints.up('xs')]: {
                borderTopRightRadius: wzRoyalPhoneSize * 1 + 'px !important',
                borderBottomRightRadius: wzRoyalPhoneSize * 1 + 'px !important',
                padding: wzRoyalPhoneSize * 0.8 + 'px !important',
                paddingLeft: wzRoyalPhoneSize * 2 + 'px !important',
                paddingRight: wzRoyalPhoneSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('sm')]: {
                borderTopRightRadius: wzRoyalTabletSize * 1 + 'px !important',
                borderBottomRightRadius: wzRoyalTabletSize * 1 + 'px !important',
                padding: wzRoyalTabletSize * 0.8 + 'px !important',
                paddingLeft: wzRoyalTabletSize * 2 + 'px !important',
                paddingRight: wzRoyalTabletSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('md')]: {
                borderTopRightRadius: wzRoyalLargeSize * 1 + 'px !important',
                borderBottomRightRadius: wzRoyalLargeSize * 1 + 'px !important',
                padding: wzRoyalLargeSize * 0.8 + 'px !important',
                paddingLeft: wzRoyalLargeSize * 2 + 'px !important',
                paddingRight: wzRoyalLargeSize * 1 + 'px !important',
            },
        },
        wzButtonBackRight:{
            borderRadius: 0 + 'px !important',
            [theme.breakpoints.up('xs')]: {
                borderTopLeftRadius: wzRoyalPhoneSize * 1 + 'px !important',
                borderBottomLeftRadius: wzRoyalPhoneSize * 1 + 'px !important',
                padding: wzRoyalPhoneSize * 0.8 + 'px !important',
                paddingRight: wzRoyalPhoneSize * 2 + 'px !important',
                paddingLeft: wzRoyalPhoneSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('sm')]: {
                borderTopLeftRadius: wzRoyalTabletSize * 1 + 'px !important',
                borderBottomLeftRadius: wzRoyalTabletSize * 1 + 'px !important',
                padding: wzRoyalTabletSize * 0.8 + 'px !important',
                paddingRight: wzRoyalTabletSize * 2 + 'px !important',
                paddingLeft: wzRoyalTabletSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('md')]: {
                borderTopLeftRadius: wzRoyalLargeSize * 1 + 'px !important',
                borderBottomLeftRadius: wzRoyalLargeSize * 1 + 'px !important',
                padding: wzRoyalLargeSize * 0.8 + 'px !important',
                paddingRight: wzRoyalLargeSize * 2 + 'px !important',
                paddingLeft: wzRoyalLargeSize * 1 + 'px !important',
            },
        },
        wzButtonCirclePadding:{
            borderRadius: 5000 + 'px !important',
            overflow: 'hidden' + ' !important',
            margin: 'auto' + ' !important',
            alignItems: 'center' + ' !important',
            justifyContent: 'center' + ' !important',

            [theme.breakpoints.up('xs')]: {
                padding: wzRoyalPhoneSize * 0.4 + 'px !important',
            },
            [theme.breakpoints.up('sm')]: {
                padding: wzRoyalTabletSize * 0.4 + 'px !important',
            },
            [theme.breakpoints.up('md')]: {
                padding: wzRoyalLargeSize * 0.4 + 'px !important',
            },
        },
        wzButtonClose:{
            position: 'absolute' + ' !important',
            borderRadius: 5000 + 'px !important',
            borderColor: WzColors.tSecondary + '1)' + ' !important',
            //borderStyle: 'solid',
            

            [theme.breakpoints.up('xs')]: {
                borderWidth: wzRoyalPhoneSize * 0.15 + 'px !important',
                top: wzRoyalPhoneSize * 0.7 + 'px !important',
                right: wzRoyalPhoneSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('sm')]: {
                borderWidth: wzRoyalTabletSize * 0.15 + 'px !important',
                top: wzRoyalTabletSize * 0.7 + 'px !important',
                right: wzRoyalTabletSize * 1 + 'px !important',
            },
            [theme.breakpoints.up('md')]: {
                borderWidth: wzRoyalLargeSize * 0.15 + 'px !important',
                top: wzRoyalLargeSize * 0.7 + 'px !important',
                right: wzRoyalLargeSize * 1 + 'px !important',
            },
        },




        wzButtonIcon: {
            [theme.breakpoints.up('xs')]: {
                height: wzRoyalPhoneSize * 1.7 + 'px !important',
                width: wzRoyalPhoneSize * 1.7 + 'px !important',
            },
            [theme.breakpoints.up('sm')]: {
                height: wzRoyalTabletSize * 1.7 + 'px !important',
                width: wzRoyalTabletSize * 1.7 + 'px !important',
            },
            [theme.breakpoints.up('md')]: {
                height: wzRoyalLargeSize * 1.7 + 'px !important',
                width: wzRoyalLargeSize * 1.7 + 'px !important',
            },
        },
        wzButtonMiniIcon: {
            [theme.breakpoints.up('xs')]: {
                height: wzRoyalPhoneSize * 1.4 + 'px !important',
                width: wzRoyalPhoneSize * 1.4 + 'px !important',
            },
            [theme.breakpoints.up('sm')]: {
                height: wzRoyalTabletSize * 1.4 + 'px !important',
                width: wzRoyalTabletSize * 1.4 + 'px !important',
            },
            [theme.breakpoints.up('md')]: {
                height: wzRoyalLargeSize * 1.4 + 'px !important',
                width: wzRoyalLargeSize * 1.4 + 'px !important',
            },
        },



        wzBgPrimary: {
            background: WzColors.hexPrimary + ' !important',
            boxShadow: '0px 0px 7px 0px ' + WzColors.tPrimary + '0.79)',
            '&:hover': {
                background: WzColors.hexResPrimary + ' !important',
                boxShadow: '0px 0px 7px 0px ' + WzColors.tResPrimary + '0.79)',
            },
        },
        wzBgDesPrimary: {
            background: WzColors.hexDesPrimary + ' !important',
            boxShadow: '0px 0px 7px 0px ' + WzColors.tDesPrimary + '0.79)',
            '&:hover': {
                background: WzColors.hexDesExtra + ' !important',
                boxShadow: '0px 0px 7px 0px ' + WzColors.tPrDesExtra + '0.79)',
            },
        },
        wzBgSecondary: {
            background: WzColors.hexSecondary + ' !important',
            boxShadow: '0px 0px 7px 0px ' + WzColors.tSecondary + '0.79)',
            '&:hover': {
                background: WzColors.hexResSecondary + ' !important',
                boxShadow: '0px 0px 7px 0px ' + WzColors.tResSecondary + '0.79)',
            },
        },
        wzBgExtra: {
            background: WzColors.hexExtra + ' !important',
            boxShadow: '0px 0px 7px 0px ' + WzColors.tExtra + '0.79)',
            '&:hover': {
                background: WzColors.hexResExtra + ' !important',
                boxShadow: '0px 0px 7px 0px ' + WzColors.tResExtra + '0.79)',
            },
        },
        wzBgCancel: {
            background: WzColors.hexCancel + ' !important',
            boxShadow: '0px 0px 10px 0px ' + WzColors.tCancel + '0.79)',
            '&:hover': {
                background: WzColors.hexResCancel + ' !important',
                boxShadow: '0px 0px 10px 0px ' + WzColors.tResCancel + '0.79)',
            },
        },
        wzBgAction: {
            background: WzColors.hexAction + ' !important',
            boxShadow: '0px 0px 10px 0px ' + WzColors.tAction + '0.79)',
            '&:hover': {
                background: WzColors.hexResAction + ' !important',
                boxShadow: '0px 0px 10px 0px ' + WzColors.tResAction + '0.79)',
            },
        },



        wzTextHuge:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * wzScalePhoneHuge,
                lineHeight: wzRoyalPhoneSize * wzScalePhoneHuge + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * wzScaleTabletHuge,
                lineHeight: wzRoyalTabletSize * wzScaleTabletHuge + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * wzScaleLargeHuge,
                lineHeight: wzRoyalLargeSize * wzScaleLargeHuge + 'px',
            },
        },
        wzTextTitle:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * wzScalePhoneTitle,
                lineHeight: wzRoyalPhoneSize * wzScalePhoneTitle + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * wzScaleTabletTitle,
                lineHeight: wzRoyalTabletSize * wzScaleTabletTitle + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * wzScaleLargeTitle,
                lineHeight: wzRoyalLargeSize * wzScaleLargeTitle + 'px',
            },
        },
        wzTextSubtitle:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * wzScalePhoneSubtitle,
                lineHeight: wzRoyalPhoneSize * wzScalePhoneSubtitle + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * wzScaleTabletSubtitle,
                lineHeight: wzRoyalTabletSize * wzScaleTabletSubtitle + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * wzScaleLargeSubtitle,
                lineHeight: wzRoyalLargeSize * wzScaleLargeSubtitle + 'px',
            },
        },
        wzTextNormalHeader:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * wzScalePhoneNormalHeader,
                lineHeight: wzRoyalPhoneSize * wzScalePhoneNormalHeader + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * wzScaleTabletNormalHeader,
                lineHeight: wzRoyalTabletSize * wzScaleTabletNormalHeader + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * wzScaleLargeNormalHeader,
                lineHeight: wzRoyalLargeSize * wzScaleLargeNormalHeader + 'px',
            },
        },
        wzTextNormal:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * 1,
                lineHeight: wzRoyalPhoneSize * 1 + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * 1,
                lineHeight: wzRoyalTabletSize * 1 + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * 1,
                lineHeight: wzRoyalLargeSize * 1 + 'px',
            },
        },
        wzTextMedium:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * wzScalePhoneMedium,
                lineHeight: wzRoyalPhoneSize * wzScalePhoneMedium + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * wzScaleTabletMedium,
                lineHeight: wzRoyalTabletSize * wzScaleTabletMedium + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * wzScaleLargeMedium,
                lineHeight: wzRoyalLargeSize * wzScaleLargeMedium + 'px',
            },
        },
        wzTextSmall:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * wzScalePhoneSmall,
                lineHeight: wzRoyalPhoneSize * wzScalePhoneSmall + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * wzScaleTabletSmall,
                lineHeight: wzRoyalTabletSize * wzScaleTabletSmall + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * wzScaleLargeSmall,
                lineHeight: wzRoyalLargeSize * wzScaleLargeSmall + 'px',
            },
        },
        wzTextMini:{
            color: WzColors.hexPrimary,
            //lineHeight: '3vh',
            //marginBottom: theme.spacing(1),
            [theme.breakpoints.up('xs')]: {
                fontSize: wzRoyalPhoneSize * wzScalePhoneMini,
                lineHeight: wzRoyalPhoneSize * wzScalePhoneMini + 'px',
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: wzRoyalTabletSize * wzScaleTabletMini,
                lineHeight: wzRoyalTabletSize * wzScaleTabletMini + 'px',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: wzRoyalLargeSize * wzScaleLargeMini,
                lineHeight: wzRoyalLargeSize * wzScaleLargeMini + 'px',
            },
        },



        wzVerticalSpace1:{
            [theme.breakpoints.up('xs')]: {
                height: wzRoyalPhoneSize * 1,
            },
            [theme.breakpoints.up('sm')]: {
                height: wzRoyalTabletSize * 1,
            },
            [theme.breakpoints.up('md')]: {
                height: wzRoyalLargeSize * 1,
            },
        },
        wzVerticalSpace5:{
            [theme.breakpoints.up('xs')]: {
                height: wzRoyalPhoneSize * 5,
            },
            [theme.breakpoints.up('sm')]: {
                height: wzRoyalTabletSize * 5,
            },
            [theme.breakpoints.up('md')]: {
                height: wzRoyalLargeSize * 5,
            },
        },



        wzHorizontalSpace1:{
            [theme.breakpoints.up('xs')]: {
                width: wzRoyalPhoneSize * 1,
            },
            [theme.breakpoints.up('sm')]: {
                width: wzRoyalTabletSize * 1,
            },
            [theme.breakpoints.up('md')]: {
                width: wzRoyalLargeSize * 1,
            },
        },



        wzPadding1:{
            [theme.breakpoints.up('xs')]: {
                padding: wzRoyalPhoneSize * 1,
            },
            [theme.breakpoints.up('sm')]: {
                padding: wzRoyalTabletSize * 1,
            },
            [theme.breakpoints.up('md')]: {
                padding: wzRoyalLargeSize * 1,
            },
        },
        wzPadding2:{
            [theme.breakpoints.up('xs')]: {
                padding: wzRoyalPhoneSize * 2,
            },
            [theme.breakpoints.up('sm')]: {
                padding: wzRoyalTabletSize * 2,
            },
            [theme.breakpoints.up('md')]: {
                padding: wzRoyalLargeSize * 2,
            },
        },
        wzPadding3:{
            [theme.breakpoints.up('xs')]: {
                padding: wzRoyalPhoneSize * 3,
            },
            [theme.breakpoints.up('sm')]: {
                padding: wzRoyalTabletSize * 3,
            },
            [theme.breakpoints.up('md')]: {
                padding: wzRoyalLargeSize * 3,
            },
        },
        wzPadding4:{
            [theme.breakpoints.up('xs')]: {
                padding: wzRoyalPhoneSize * 4,
            },
            [theme.breakpoints.up('sm')]: {
                padding: wzRoyalTabletSize * 4,
            },
            [theme.breakpoints.up('md')]: {
                padding: wzRoyalLargeSize * 4,
            },
        },
        wzPadding5:{
            [theme.breakpoints.up('xs')]: {
                padding: wzRoyalPhoneSize * 5,
            },
            [theme.breakpoints.up('sm')]: {
                padding: wzRoyalTabletSize * 5,
            },
            [theme.breakpoints.up('md')]: {
                padding: wzRoyalLargeSize * 5,
            },
        },



        wzMarginRight1:{
            [theme.breakpoints.up('xs')]: {
                marginRight: wzRoyalPhoneSize * 1,
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: wzRoyalTabletSize * 1,
            },
            [theme.breakpoints.up('md')]: {
                marginRight: wzRoyalLargeSize * 1,
            },
        },
    }));

    const finalStyles = useStyles();

    return finalStyles;
}

export default Styles
