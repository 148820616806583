"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var prop_types_1 = require("prop-types");
var react_1 = __importStar(require("react"));
var uiFunctions_1 = require("./uiFunctions");
var ScrollRenderer = function (props) {
    var list = props.list, renderItem = props.renderItem, groupSeparator = props.groupSeparator;
    var _a = react_1.useState({ renderList: [], index: 0 }), render = _a[0], setRender = _a[1];
    var _b = react_1.useState(false), mounted = _b[0], setMounted = _b[1];
    var _c = react_1.useState(-1), setupCount = _c[0], setSetupCount = _c[1];
    var containerRef = react_1.createRef();
    var renderThisItem = uiFunctions_1.handleRenderItem(renderItem, uiFunctions_1.handleRenderGroupSeparator(groupSeparator));
    var updateRenderInfo = function (count) {
        if (count === void 0) { count = 10; }
        if (render.index < list.length) {
            var index = render.index + count;
            setRender({
                renderList: list.slice(0, index),
                index: index
            });
        }
    };
    var onScroll = function (span) { return function () {
        requestAnimationFrame(function () {
            if (span) {
                var startingPoint = span.parentNode.offsetTop + span.parentNode.offsetHeight;
                var anchorPos = span.offsetTop - span.parentNode.scrollTop;
                if (anchorPos <= (startingPoint + (span.parentNode.offsetHeight * 2))) {
                    updateRenderInfo();
                }
            }
        });
    }; };
    react_1.useEffect(function () {
        setMounted(true);
        return function () {
            setMounted(false);
        };
    }, []);
    react_1.useLayoutEffect(function () {
        if (mounted) { // reset list on list change
            var span_1 = containerRef.current;
            var pos_1 = span_1.parentNode.scrollTop;
            var index = Math.max(render.renderList.length, setupCount);
            setRender({
                renderList: list.slice(0, index),
                index: index
            });
            requestAnimationFrame(function () {
                if (span_1 && span_1.parentNode) {
                    span_1.parentNode.scrollTop = pos_1;
                }
            });
        }
    }, [list]);
    react_1.useLayoutEffect(function () {
        var span = containerRef.current;
        var handleScroll = onScroll(span);
        var container = null;
        if (span) {
            container = span.parentNode;
            requestAnimationFrame(function () {
                // populate double the container height of items
                if (render.index === 0 || (container.scrollHeight <= (container.offsetHeight * 2))) {
                    updateRenderInfo();
                }
                else if (setupCount === -1) {
                    setSetupCount(render.index);
                }
            });
            container.addEventListener('scroll', handleScroll, { passive: true });
        }
        return function () {
            if (span) {
                container.removeEventListener('scroll', handleScroll, { passive: true });
            }
        };
    }, [render.index, list.length]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        render.renderList.map(renderThisItem),
        react_1.default.createElement("span", { ref: containerRef, style: { visibility: 'hidden', height: 1 }, className: "___scroll-renderer-anchor" })));
};
ScrollRenderer.propTypes = {
    list: prop_types_1.arrayOf(prop_types_1.any).isRequired,
    renderItem: prop_types_1.func.isRequired,
    groupSeparator: prop_types_1.oneOfType([prop_types_1.node, prop_types_1.func, prop_types_1.element])
};
ScrollRenderer.defaultProps = {
    groupSeparator: null
};
exports.default = ScrollRenderer;
