import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from "react-router-dom";

import Swal from 'sweetalert2';

import FlatList from 'flatlist-react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DatePicker';
import esLocale from 'date-fns/locale/es';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LogoutIcon from '@mui/icons-material/Logout';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ClearIcon from '@mui/icons-material/Clear';
import FaceIcon from '@mui/icons-material/Face';
import PublicIcon from '@mui/icons-material/Public';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import SearchIcon from '@mui/icons-material/Search';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PasswordIcon from '@mui/icons-material/Password';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { apiGetUsers, apiGetTotalUsers, apiSendVerificationCode, apiUpdateUserType } from "../components/ServicesApi";
import { token, setToken, userData, setUserData } from "../components/Config";
import Styles from "../components/Styles";
import WzColors from "../components/WzColors";

esLocale.options.weekStartsOn = 0

const getPrettyDate = (date) => {
    return date < 10 ? '0' + date : '' + date; // ('' + month) for string result
}

const reverseDate = (date) => {
    var day = date[0]+date[1]
    var month = date[3]+date[4]
    var year = date[6]+date[7]+date[8]+date[9]
    return year + '-' + month + '-' + day
}

const getMonthName = (month) => {
    switch(month){
        case 0:
            return 'Enero'
        case 1:
            return 'Febrero'
        case 2:
            return 'Marzo'
        case 3:
            return 'Abril'
        case 4:
            return 'Mayo'
        case 5:
            return 'Junio'
        case 6:
            return 'Julio'
        case 7:
            return 'Agosto'
        case 8:
            return 'Septiembre'
        case 9:
            return 'Octubre'
        case 10:
            return 'Noviembre'
        case 11:
            return 'Diciembre'
    }
}

const getContinent = (country) => {
    var continentsArray = [
        { 
            code: 'ALB',
            country: 'Albania',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'AND',
            country: 'Andorra',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'BIH',
            country: 'Bosnia y Herzegovina',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'HRV',
            country: 'Croacia',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'SVN',
            country: 'Eslovenia',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'ESP',
            country: 'España',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'GRC',
            country: 'Grecia',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'ITA',
            country: 'Italia',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'MKD',
            country: 'Macedonia',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'MLT',
            country: 'Malta',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'MNE',
            country: 'Montenegro',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'PRT',
            country: 'Portugal',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'SMR',
            country: 'San Marino',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'VAT',
            country: 'Santa Sede',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'SRB',
            country: 'Serbia',
            region: 'Europa meridional',
            continent: 'Europa',
        },
        { 
            code: 'DEU',
            country: 'Alemania',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'AUT',
            country: 'Austria',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'BEL',
            country: 'Bélgica',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'FRA',
            country: 'Francia',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'LIE',
            country: 'Liechtenstein',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'LUX',
            country: 'Luxemburgo',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'MCO',
            country: 'Mónaco',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'NLD',
            country: 'Países Bajos',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'CHE',
            country: 'Suiza',
            region: 'Europa occidental',
            continent: 'Europa',
        },
        { 
            code: 'BLR',
            country: 'Belarús',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'BGR',
            country: 'Bulgaria',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'HUN',
            country: 'Hungría',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'MDA',
            country: 'Moldavia',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'POL',
            country: 'Polonia',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'CZE',
            country: 'República Checa',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'SVK',
            country: 'República Eslovaca',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'ROU',
            country: 'Rumanía',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'RUS',
            country: 'Rusia',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'UKR',
            country: 'Ucrania',
            region: 'Europa oriental',
            continent: 'Europa',
        },
        { 
            code: 'DNK',
            country: 'Dinamarca',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'EST',
            country: 'Estonia',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'FIN',
            country: 'Finlandia',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'IRL',
            country: 'Irlanda',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'ISL',
            country: 'Islandia',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'LVA',
            country: 'Letonia',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'LTU',
            country: 'Lituania',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'NOR',
            country: 'Noruega',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'GBR',
            country: 'Reino Unido',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'SWE',
            country: 'Suecia',
            region: 'Europa septentrional',
            continent: 'Europa',
        },
        { 
            code: 'AGO',
            country: 'Angola',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'CMR',
            country: 'Camerún',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'COG',
            country: 'Congo',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'GAB',
            country: 'Gabón',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'GNQ',
            country: 'Guinea Ecuatorial',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'CAF',
            country: 'República Centroafricana',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'COD',
            country: 'República Democrática del Congo',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'STP',
            country: 'Santo Tomé y Príncipe',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'TCD',
            country: 'Chad',
            region: 'África central',
            continent: 'África',
        },
        { 
            code: 'BWA',
            country: 'Botswana',
            region: 'África meridional',
            continent: 'África',
        },
        { 
            code: 'LSO',
            country: 'Lesotho',
            region: 'África meridional',
            continent: 'África',
        },
        { 
            code: 'NAM',
            country: 'Namibia',
            region: 'África meridional',
            continent: 'África',
        },
        { 
            code: 'ZAF',
            country: 'Sudáfrica',
            region: 'África meridional',
            continent: 'África',
        },
        { 
            code: 'SWZ',
            country: 'Swazilandia',
            region: 'África meridional',
            continent: 'África',
        },
        { 
            code: 'BEN',
            country: 'Benin',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'BFA',
            country: 'Burkina Faso',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'CPV',
            country: 'Cabo Verde',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'CIV',
            country: 'Costa de Marfil',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'GMB',
            country: 'Gambia',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'GHA',
            country: 'Ghana',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'GIN',
            country: 'Guinea',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'GNB',
            country: 'Guinea-Bissau',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'LBR',
            country: 'Liberia',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'MLI',
            country: 'Mali',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'MRT',
            country: 'Mauritania',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'NER',
            country: 'Níger',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'NGA',
            country: 'Nigeria',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'SEN',
            country: 'Senegal',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'SLE',
            country: 'Sierra Leona',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'TGO',
            country: 'Togo',
            region: 'África occidental',
            continent: 'África',
        },
        { 
            code: 'BDI',
            country: 'Burundi',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'COM',
            country: 'Comores',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'DJI',
            country: 'Djibouti',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'ERI',
            country: 'Eritrea',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'ETH',
            country: 'Etiopía',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'KEN',
            country: 'Kenia',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'MDG',
            country: 'Madagascar',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'MWI',
            country: 'Malawi',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'MUS',
            country: 'Mauricio',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'MOZ',
            country: 'Mozambique',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'RWA',
            country: 'Ruanda',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'SYC',
            country: 'Seychelles',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'SOM',
            country: 'Somalia',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'TZA',
            country: 'Tanzania',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'UGA',
            country: 'Uganda',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'ZMB',
            country: 'Zambia',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'ZWE',
            country: 'Zimbabwe',
            region: 'África oriental',
            continent: 'África',
        },
        { 
            code: 'DZA',
            country: 'Argelia',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'EGY',
            country: 'Egipto',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'LBY',
            country: 'Libia',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'MAR',
            country: 'Marruecos',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'ESH',
            country: 'Sáhara Occidental',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'SSD',
            country: 'Sudán del Sur',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'SDN',
            country: 'Sudán',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'TUN',
            country: 'Túnez',
            region: 'África septentrional',
            continent: 'África',
        },
        { 
            code: 'BLZ',
            country: 'Belice',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'CRI',
            country: 'Costa Rica',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'SLV',
            country: 'El Salvador',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'GTM',
            country: 'Guatemala',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'HND',
            country: 'Honduras',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'MEX',
            country: 'México',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'NIC',
            country: 'Nicaragua',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'PAN',
            country: 'Panamá',
            region: 'América central',
            continent: 'América',
        },
        { 
            code: 'CAN',
            country: 'Canadá',
            region: 'América del Norte',
            continent: 'América',
        },
        { 
            code: 'USA',
            country: 'Estados Unidos de América',
            region: 'América del Norte',
            continent: 'América',
        },
        { 
            code: 'ARG',
            country: 'Argentina',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'BOL',
            country: 'Bolivia',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'BRA',
            country: 'Brasil',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'COL',
            country: 'Colombia',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'ECU',
            country: 'Ecuador',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'GUY',
            country: 'Guyana',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'PRY',
            country: 'Paraguay',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'PER',
            country: 'Perú',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'SUR',
            country: 'Surinam',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'URY',
            country: 'Uruguay',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'VEN',
            country: 'Venezuela',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'CHL',
            country: 'Chile',
            region: 'América del Sur',
            continent: 'América',
        },
        { 
            code: 'ATG',
            country: 'Antigua y Barbuda',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'BHS',
            country: 'Bahamas',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'BRB',
            country: 'Barbados',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'CUB',
            country: 'Cuba',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'DMA',
            country: 'Dominica',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'GRD',
            country: 'Granada',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'HTI',
            country: 'Haití',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'JAM',
            country: 'Jamaica',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'DOM',
            country: 'República Dominicana',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'KNA',
            country: 'San Cristóbal y Nieves',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'LCA',
            country: 'Santa Lucía',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'VCT',
            country: 'San Vicente y las Granadinas',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'TTO',
            country: 'Trinidad y Tobago',
            region: 'Caribe',
            continent: 'América',
        },
        { 
            code: 'KAZ',
            country: 'Kazajstán',
            region: 'Asia central',
            continent: 'Asia',
        },
        { 
            code: 'KGZ',
            country: 'Kirguistán',
            region: 'Asia central',
            continent: 'Asia',
        },
        { 
            code: 'TJK',
            country: 'Tadyikistán',
            region: 'Asia central',
            continent: 'Asia',
        },
        { 
            code: 'TKM',
            country: 'Turkmenistán',
            region: 'Asia central',
            continent: 'Asia',
        },
        { 
            code: 'UZB',
            country: 'Uzbekistán',
            region: 'Asia central',
            continent: 'Asia',
        },
        { 
            code: 'AFG',
            country: 'Afganistán',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'BGD',
            country: 'Bangladesh',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'BTN',
            country: 'Bhután',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'IND',
            country: 'India',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'IRN',
            country: 'Irán',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'MDV',
            country: 'Maldivas',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'NPL',
            country: 'Nepal',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'PAK',
            country: 'Pakistán',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'LKA',
            country: 'Sri Lanka',
            region: 'Asia meridional',
            continent: 'Asia',
        },
        { 
            code: 'SAU',
            country: 'Arabia Saudí',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'ARM',
            country: 'Armenia',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'AZE',
            country: 'Azerbaiyán',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'BHR',
            country: 'Bahréin',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'ARE',
            country: 'Emiratos Árabes Unidos',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'GEO',
            country: 'Georgia',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'YEM',
            country: 'Yemen',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'IRQ',
            country: 'Iraq',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'ISR',
            country: 'Israel',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'JOR',
            country: 'Jordania',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'KWT',
            country: 'Kuwait',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'LBN',
            country: 'Líbano',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'OMN',
            country: 'Omán',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'QAT',
            country: 'Qatar',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'SYR',
            country: 'Siria',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'PSE',
            country: 'Territorios Palestinos (o Palestina)',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'TUR',
            country: 'Turquía',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'CYP',
            country: 'Chipre',
            region: 'Asia occidental',
            continent: 'Asia',
        },
        { 
            code: 'PRK',
            country: 'Corea del Norte',
            region: 'Asia oriental',
            continent: 'Asia',
        },
        { 
            code: 'KOR',
            country: 'Corea del Sur',
            region: 'Asia oriental',
            continent: 'Asia',
        },
        { 
            code: 'JPN',
            country: 'Japón',
            region: 'Asia oriental',
            continent: 'Asia',
        },
        { 
            code: 'MNG',
            country: 'Mongolia',
            region: 'Asia oriental',
            continent: 'Asia',
        },
        { 
            code: 'TWN',
            country: 'Taiwán, China',
            region: 'Asia oriental',
            continent: 'Asia',
        },
        { 
            code: 'CHN',
            country: 'China',
            region: 'Asia oriental',
            continent: 'Asia',
        },
        { 
            code: 'BRN',
            country: 'Brunei',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'KHM',
            country: 'Camboya',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'PHL',
            country: 'Filipinas',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'IDN',
            country: 'Indonesia',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'LAO',
            country: 'Laos',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'MYS',
            country: 'Malasia',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'MMR',
            country: 'Myanmar',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'SGP',
            country: 'Singapur',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'THA',
            country: 'Tailandia',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'TLS',
            country: 'Timor Oriental',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'VNM',
            country: 'Vietnam',
            region: 'Asia sudoriental',
            continent: 'Asia',
        },
        { 
            code: 'AUS',
            country: 'Australia',
            region: 'Australia y Nueva Zelanda',
            continent: 'Oceanía',
        },
        { 
            code: 'NZL',
            country: 'Nueva Zelanda',
            region: 'Australia y Nueva Zelanda',
            continent: 'Oceanía',
        },
        { 
            code: 'FJI',
            country: 'Fiji',
            region: 'Melanesia',
            continent: 'Oceanía',
        },
        { 
            code: 'SLB',
            country: 'Islas Salomón',
            region: 'Melanesia',
            continent: 'Oceanía',
        },
        { 
            code: 'PNG',
            country: 'Papúa Nueva Guinea',
            region: 'Melanesia',
            continent: 'Oceanía',
        },
        { 
            code: 'VUT',
            country: 'Vanuatu',
            region: 'Melanesia',
            continent: 'Oceanía',
        },
        { 
            code: 'MHL',
            country: 'Islas Marshall',
            region: 'Micronesia',
            continent: 'Oceanía',
        },
        { 
            code: 'KIR',
            country: 'Kiribati',
            region: 'Micronesia',
            continent: 'Oceanía',
        },
        { 
            code: 'FSM',
            country: 'Micronesia',
            region: 'Micronesia',
            continent: 'Oceanía',
        },
        { 
            code: 'NRU',
            country: 'Nauru',
            region: 'Micronesia',
            continent: 'Oceanía',
        },
        { 
            code: 'PLW',
            country: 'Palaos',
            region: 'Micronesia',
            continent: 'Oceanía',
        },
        { 
            code: 'WSM',
            country: 'Samoa',
            region: 'Polinesia',
            continent: 'Oceanía',
        },
        { 
            code: 'TON',
            country: 'Tonga',
            region: 'Polinesia',
            continent: 'Oceanía',
        },
        { 
            code: 'TUV',
            country: 'Tuvalu',
            region: 'Polinesia',
            continent: 'Oceanía',
        },
    ]

    var continentFound = continentsArray.find(element => element.country.toLowerCase().normalize('NFD').replaceAll(/[\u0300-\u036f]/g, "").includes(country.toLowerCase().normalize('NFD').replaceAll(/[\u0300-\u036f]/g, "")))

    if(continentFound != null){
        return continentFound.continent
    }else{
        return 'No definido'
    }
}

const getUsers = (setRefreshing, search, typeSearch, startDate, endDate, setUsersData) => {
    setRefreshing('Buscando usuarios...')
    //console.log('Buscando usuarios... ' + startDate + ' | ' + endDate)
    
    apiGetUsers(search, typeSearch, startDate, endDate).then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('USERS DATA:', response)
        if(response != null){
            if(response.status){
                setUsersData(response.data)
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }else{
            setToken('')
            setUserData('{}')
            useNavigate().navigate(`/`, { replace: true, state: {} });
        }
        setRefreshing('')
    });
}

const getChartInfo = (setRefreshing, typeSearch, setCountriesEachCount, setCertificadosCompletados) => {
    setRefreshing('Cargado estadísticas...')
    //console.log('Buscando usuarios... ' + startDate + ' | ' + endDate)
    
    apiGetUsers('', typeSearch, '', '').then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('CHART DATA:', response)
        if(response != null){
            if(response.status){
                var countriesArray = []

                var certificadosCompletados = 0

                //Obtiene todos los paises y total de certificados
                for (var i = 0; i < response.data.length; i++) {
                    countriesArray.push(response.data[i].pais)
                    
                    //Disque cuenta más o menos el total de los certificados obtenidos
                    if(response.data[i].cursosClases[0].completado[0] == true && response.data[i].cursosClases[0].completado[1] == true){
                        certificadosCompletados += 2
                    }else if(response.data[i].cursosClases[0].completado[0] == true || response.data[i].cursosClases[0].completado[1] == true){
                        certificadosCompletados++
                    }

                    if(response.data[i].cursosClases[1].completado[0] == true && response.data[i].cursosClases[1].completado[1] == true && response.data[i].cursosClases[1].completado[2] == true){
                        certificadosCompletados += 3
                    }else if(response.data[i].cursosClases[1].completado[0] == true){
                        certificadosCompletados++
                    }else if(response.data[i].cursosClases[1].completado[1] == true){
                        certificadosCompletados++
                    }else if(response.data[i].cursosClases[1].completado[2] == true){
                        certificadosCompletados++
                    }
                    //Hasta aquí
                }


                for (var i = 0; i < response.data.length; i++) {
                    //countriesArray.push(response.data[i].pais)
                }

                //Elimina los paises repetidos
                var finalCountriesArray = [ ...new Set(countriesArray) ];

                //Cuenta cuántos hay de cada pais revisando el nombre del pais en el array con paises repetidos
                var countriesEachCount = finalCountriesArray.map(value => ({ continent: getContinent(value), country: value, users: countriesArray.filter(str => str === value).length }));
                //console.log('LOS PAISES: ' + JSON.stringify(countriesEachCount))

                var finalNumbersCountriesEachCount = []

                //Obtiene solo los números que se contaron de cada país
                for (var y = 0; y < countriesEachCount.length; y++) {
                    finalNumbersCountriesEachCount.push(countriesEachCount[y].users)
                }

                var finalCountriesEachCount = []

                //Ordena los números de mayor a menor
                finalCountriesEachCount = finalNumbersCountriesEachCount.sort(function(a, b) {
                    return a - b;
                });

                var countriesEachCountSorted = []

                //Ordena el array con nombres de paises y cantidad de usuarios de mayor a menor
                for (var i = finalCountriesEachCount.length; i > 0 ; i--) {
                    for (var w = 0; w < countriesEachCount.length; w++) {
                        if(countriesEachCount[w].users == finalCountriesEachCount[i]){
                            countriesEachCountSorted.push(countriesEachCount[w])
                        }
                    }
                }

                //Borra los objetos repetidos que da el de arriba por error
                var finalCountriesEachCountSorted = [ ...new Set(countriesEachCountSorted) ];



                //console.log(`Total de paises: ${JSON.stringify(finalCountriesEachCountSorted)}`)
                
                setCountriesEachCount(finalCountriesEachCountSorted)
                setCertificadosCompletados(certificadosCompletados)
                
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }else{
            setToken('')
            setUserData('{}')
            useNavigate().navigate(`/`, { replace: true, state: {} });
        }
        setRefreshing('')
    });
}

const getTotalUsers = (setRefreshing, setUsersCount) => {
    setRefreshing('Obteniendo total de usuarios...')
    
    apiGetTotalUsers().then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('Total de usuarios:', response)
        if(response != null){
            if(response.status){
                setUsersCount(response.data)
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }else{
            setToken('')
            setUserData('{}')
            useNavigate().navigate(`/`, { replace: true, state: {} });
        }
        setRefreshing('')
    });
}

const sendVerificationCode = (setRefreshing, userData) => {
    setRefreshing('Reenviando código...')
    
    apiSendVerificationCode(userData.user_id).then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('Total de usuarios:', response)
        if(response != null){
            if(response.status){
                Swal.fire({
                    icon: 'success',
                    title: 'Código reenviado',
                    text: `:D\nHemos enviado el código de verificación a:\n${userData.email}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }else{
            setToken('')
            setUserData('{}')
            useNavigate().navigate(`/`, { replace: true, state: {} });
        }
        setRefreshing('')
    });
}

const updateUserType = (setRefreshing, userData, userType, doGetUsers, typeSearch) => {
    setRefreshing('Actualizando tipo de usuario...')
    
    apiUpdateUserType(userData.user_id, userType).then(res => res.json())
    .catch(error => {
        //console.log(error)
        Swal.fire({
            icon: 'question',
            title: '¿Tienes conexión a internet?',
            text: 'Si así es entonces no te preocupes, la conexión al servidor regresará pronto :D',
            confirmButtonText:
                'Aceptar',
        })
        setRefreshing('')
    })
    .then(response => {
        //console.log('Total de usuarios:', response)
        if(response != null){
            if(response.status){
                Swal.fire({
                    icon: 'success',
                    title: 'Usuario actualizado correctamente',
                    text: `Se cambio el tipo de usuario para: ${userData.nombre}`,
                    confirmButtonText:
                        'Aceptar',
                })
                doGetUsers(getTypeOrderServer(typeSearch))
            }else{
                //console.log('Pos el status es false')
                Swal.fire({
                    icon: 'error',
                    title: 'Algo salió mal',
                    html: `${response.message}`,
                    confirmButtonText:
                        'Aceptar',
                })
            }
        }else{
            setToken('')
            setUserData('{}')
            useNavigate().navigate(`/`, { replace: true, state: {} });
        }
        setRefreshing('')
    });
}

const getTypeOrderServer = (typeSearch) => {
    switch(typeSearch){
        case 'Nombre':
            return 'nombre';
        case 'País':
            return 'pais';
        case 'email':
            return 'email';
    }
}

const getTypeOrderNormal = (typeSearch) => {
    switch(typeSearch){
        case 'nombre':
            return 'Nombre';
        case 'pais':
            return 'País';
        case 'email':
            return 'email';
    }
}

const getCountriesFiltered = (countries, continentSearch) => {
    var finalCountries = []

    if(continentSearch == ''){
        finalCountries = countries
    }else{
        for (var i = 0; i < countries.length; i++) {
            if(countries[i].continent.toLowerCase().normalize('NFD').replaceAll(/[\u0300-\u036f]/g, "").includes(continentSearch.toLowerCase().normalize('NFD').replaceAll(/[\u0300-\u036f]/g, "")) || countries[i].country.toLowerCase().normalize('NFD').replaceAll(/[\u0300-\u036f]/g, "").includes(continentSearch.toLowerCase().normalize('NFD').replaceAll(/[\u0300-\u036f]/g, ""))){
                finalCountries.push(countries[i])
            }
        }
    }

    return finalCountries
}

const PrimaryLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.mode === 'light' ? WzColors.tPrimary + '0.2)' : WzColors.tPrimary + '0.8)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? WzColors.hexPrimary : WzColors.hexResPrimary,
    },
}));

const ActionLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.mode === 'light' ? WzColors.tAction + '0.2)' : WzColors.tAction + '0.8)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? WzColors.hexAction : WzColors.hexResAction,
    },
}));

function Home(props) {

    const navigate = useNavigate();

    const {
        wzContainer,
        wzCardContainerRounded,
        wzWaitingContianer,
        wzContainerResponsiveWidth60,
        wzFormContainer,
        wzFormHeaderContainer,
        wzFormContentContainer,
        wzFormBackgroundContainer,
        wzGroupContainer,

        wzTextHuge,
        wzTextNormalHeader,
        wzTextNormal,
        wzTextMedium,
        wzTextSmall,

        wzProfileImage,
        wzButtonIcon,
        wzButtonMiniIcon,
        
        wzTextInput,
        
        wzButtonBottom,
        wzButtonBack,
        wzButtonBackRight,
        wzButtonCirclePadding,
        wzButtonClose,


        wzVerticalSpace1,
        wzHorizontalSpace1,
        wzPadding2,
        wzPadding1,

        wzBgPrimary,
        wzBgDesPrimary,
        wzBgExtra,
    } = Styles();

    const { preAlways, preWorld, preTypeSearch, preSearch, preStartDate, preEndDate } = useParams()

    useEffect(() => {
        scrollToTheTop()

        if(token() != null && token() != ''){
            //For chart
            doGetChartInfo(getTypeOrderServer(typeSearch))
            
            //For search
            doGetUsers(getTypeOrderServer(typeSearch))
            doGetTotalUsers()
        }else{
            navigate(`/`, { replace: true, state: {} });
        }
    }, [])

    const scrollToTheTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const [usersCount, setUsersCount] = useState(0);
    const [usersData, setUsersData] = useState([]);
    const [countriesEachCount, setCountriesEachCount] = useState([]);
    const [certificadosCompletados, setCertificadosCompletados] = useState(0);

    const [worldActive, setWorldActive] = useState(preWorld == 1 ? true : false);
    const [alwaysActive, setAlwaysActive] = useState(preAlways == 1 ? true : false);
    const [startDate, setStartDate] = useState(new Date(reverseDate(preStartDate)+'T13:59:00Z'));
    const [endDate, setEndDate] = useState(new Date(reverseDate(preEndDate)+'T13:59:00Z'));
    const [search, setSearch] = useState(preSearch == '*' ? '' : preSearch.replaceAll('+','xMl3Jk').replaceAll('/','Por24Wz').replaceAll('=','Ml32'));
    const [typeSearch, setTypeSearch] = useState(getTypeOrderNormal(preTypeSearch));
    const [continentSearch, setContinentSearch] = useState('');

    const [userDetails, setUserDetails] = useState({});
    const [userDetailsType, setUserDetailsType] = useState(0);
    const [modalUserDetails, setModalUserDetails] = useState(false);

    const [refreshing, setRefreshing] = useState(false);

    const doGetUsers = (typeSearched) => {
        var reverseStartDate = ''
        var reverseEndDate = ''
        if(search != ''){
            if(!alwaysActive){
                reverseStartDate = startDate.getFullYear() + '-' + getPrettyDate(startDate.getMonth()+1) + '-' + getPrettyDate(startDate.getDate())
                reverseEndDate = endDate.getFullYear() + '-' + getPrettyDate(endDate.getMonth()+1) + '-' + getPrettyDate(endDate.getDate())
            }

            getUsers(setRefreshing, search, typeSearched, reverseStartDate, reverseEndDate, setUsersData)
        }

        var proAlwaysActive = alwaysActive ? 1 : 0
        var proWorldActive = worldActive ? 1 : 0
        var proTypeSearch = typeSearched
        var proSearch = search == '' ? '*' : search.replaceAll('+','xMl3Jk').replaceAll('/','Por24Wz').replaceAll('=','Ml32')
        var proStartDate = getPrettyDate(startDate.getDate()) + '-' + getPrettyDate(startDate.getMonth() + 1) + '-' + startDate.getFullYear()
        var proEndDate = getPrettyDate(endDate.getDate()) + '-' + getPrettyDate(endDate.getMonth() + 1) + '-' + endDate.getFullYear()
        navigate(`/Inicio/${proAlwaysActive}/${proWorldActive}/${proTypeSearch}/${proSearch}/${proStartDate}/${proEndDate}`, { replace: false, state: {} });
    }

    const doGetChartInfo = (typeSearched) => {
        getChartInfo(setRefreshing, typeSearched, setCountriesEachCount, setCertificadosCompletados)
    }

    const doGetTotalUsers = () => {
        getTotalUsers(setRefreshing, setUsersCount)
    }

    const doSendVerificationCode = (itemUser) => {
        sendVerificationCode(setRefreshing, itemUser)
    }

    const doUpdateUserType = (userType) => {
        updateUserType(setRefreshing, userDetails, userType, doGetUsers, typeSearch)
    }

    const clearSearch = () => {
        setUsersData([])
        setCountriesEachCount([])
        setCertificadosCompletados(0)

        setWorldActive(false)
        setAlwaysActive(true)
        setStartDate(new Date())
        setEndDate(new Date())
        setSearch('')
        setTypeSearch('Nombre')
    }

    return (
        <Grid className={wzContainer} style={{ overflow: modalUserDetails ? 'hidden' : 'scroll' }}>
            <Grid className={wzPadding2}>

                {/*PC VIEW*/}
                <Grid container justifyContent="center" sx={{ display: { sm: 'none', md: 'flex', xs: 'none' } }}>
                    <Grid className={`${wzCardContainerRounded} ${wzPadding2} ${wzContainerResponsiveWidth60}`} style={{ position: 'relative' }}>
                        <p className={wzTextNormalHeader} style={{ textAlign: 'center', marginTop: 0, fontWeight: '600' }}>- Buscador -</p>

                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Rango de fechas:</p>
                            <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                    <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Siempre:</p>
                                    
                                    <Grid className={wzHorizontalSpace1}/>

                                    <Button
                                        className={`${wzButtonCirclePadding} ${alwaysActive ? wzBgPrimary : wzBgDesPrimary}`}
                                        onClick={() => {
                                            setAlwaysActive(!alwaysActive)
                                        }}
                                    >
                                        <AllInclusiveIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        {
                                            alwaysActive ?
                                                <CircleIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                            :
                                                <CircleOutlinedIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                        }
                                    </Button>
                                </Grid>
                                
                                <Grid className={wzHorizontalSpace1}/>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid className={wzHorizontalSpace1}/>

                                <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                    <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Mundo:</p>
                                    
                                    <Grid className={wzHorizontalSpace1}/>

                                    <Button
                                        className={`${wzButtonCirclePadding} ${worldActive ? wzBgPrimary : wzBgDesPrimary}`}
                                        onClick={() => {
                                            setWorldActive(!worldActive)
                                        }}
                                    >
                                        <PublicIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        {
                                            worldActive ?
                                                <CircleIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                            :
                                                <CircleOutlinedIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                            !alwaysActive ?
                                <Grid className={wzVerticalSpace1}/>
                            : null
                        }

                        {
                            !alwaysActive ?
                                <Grid container direction="row">
                                    <Grid container direction="row" alignItems="center" justifyContent="flex-start" style={{ flex: 1, width: 'auto' }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DesktopDatePicker
                                                label="Custom input"
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue)
                                                }}
                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className={wzTextNormal} style={{ marginTop: 0, marginBottom: 0 }}>{getPrettyDate(startDate.getDate())} de {getMonthName(startDate.getMonth())} de {startDate.getFullYear()}</p>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <Grid className={wzHorizontalSpace1}/>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Grid className={wzHorizontalSpace1}/>

                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DesktopDatePicker
                                                label="Custom input"
                                                value={endDate}
                                                onChange={(newValue) => {
                                                    setEndDate(newValue)
                                                }}
                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className={wzTextNormal} style={{ marginTop: 0, marginBottom: 0 }}>{getPrettyDate(endDate.getDate())} de {getMonthName(endDate.getMonth())} de {endDate.getFullYear()}</p>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <Grid className={wzHorizontalSpace1}/>
                                        <Grid className={wzHorizontalSpace1}/>
                                    </Grid>
                                </Grid>
                            : null
                        }

                        <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra, textAlign: 'center' }}>Escribe tu búsqueda:</p>
                        <Grid container direction="row">
                            <Tooltip title="Cambiar órden">
                                <Button
                                    className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                    onClick={() => {
                                        if(typeSearch == 'Nombre'){
                                            setTypeSearch('País')
                                            doGetUsers('pais')
                                        }else if(typeSearch == 'País'){
                                            setTypeSearch('email')
                                            doGetUsers('email')
                                        }else{
                                            setTypeSearch('Nombre')
                                            doGetUsers('nombre')
                                        }
                                    }}
                                >
                                    {
                                        typeSearch == 'Nombre' ?
                                            <FaceIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        :
                                            typeSearch == 'País' ?
                                                <PublicIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                            :
                                                <AlternateEmailIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                    }
                                    <FindReplaceIcon className={wzButtonMiniIcon} style={{ color: 'rgba(255, 255, 255, 0.8)' }} />
                                </Button>
                            </Tooltip>

                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>
                            
                            <TextField
                                className={wzTextInput}
                                style={{ flex: 1, width: 'auto' }}
                                label={"Pablo Nájera | ejemplo@ejemplo.com | México"}
                                variant="outlined"
                                type="default"
                                id="custom-css-outlined-input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                inputProps={{
                                    className: wzTextNormal,
                                    onKeyPress: event => {
                                        const { key } = event;
                                        //console.log(key);
                                        if (key === "Enter") {
                                            doGetUsers(getTypeOrderServer(typeSearch))
                                        }
                                    }
                                }}
                            />

                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>

                            <Tooltip title="Buscar">
                                <IconButton
                                    className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                    onClick={() => {
                                        doGetUsers(getTypeOrderServer(typeSearch))
                                    }}
                                >
                                    <TravelExploreIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>

                            <Grid className={wzHorizontalSpace1}/>

                            <Tooltip title="Limpiar búsqueda">
                                <IconButton
                                    className={`${wzButtonCirclePadding} ${wzBgExtra}`}
                                    onClick={() => {
                                        clearSearch()
                                    }}
                                >
                                    <ClearIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        <p className={wzTextMedium} style={{ color: WzColors.hexPrimary, marginBottom: 0, textAlign: 'center' }}>Ordenado por: <span style={{ fontWeight: 'bold' }}>{typeSearch}</span></p>
                    </Grid>
                </Grid>

                {/*TABLET VIEW*/}
                <Grid container justifyContent="center" sx={{ display: { md: 'none', sm: 'flex', xs: 'none' } }}>
                    <Grid className={`${wzCardContainerRounded} ${wzPadding2} ${wzContainerResponsiveWidth60}`} style={{ position: 'relative' }}>
                        <p className={wzTextNormalHeader} style={{ textAlign: 'center', marginTop: 0, fontWeight: '600' }}>- Buscador -</p>

                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Rango de fechas:</p>
                            <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                    <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Siempre:</p>
                                    
                                    <Grid className={wzHorizontalSpace1}/>

                                    <Button
                                        className={`${wzButtonCirclePadding} ${alwaysActive ? wzBgPrimary : wzBgDesPrimary}`}
                                        onClick={() => {
                                            setAlwaysActive(!alwaysActive)
                                        }}
                                    >
                                        <AllInclusiveIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        {
                                            alwaysActive ?
                                                <CircleIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                            :
                                                <CircleOutlinedIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                        }
                                    </Button>
                                </Grid>
                                
                                <Grid className={wzHorizontalSpace1}/>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid className={wzHorizontalSpace1}/>

                                <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                    <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Mundo:</p>
                                    
                                    <Grid className={wzHorizontalSpace1}/>

                                    <Button
                                        className={`${wzButtonCirclePadding} ${worldActive ? wzBgPrimary : wzBgDesPrimary}`}
                                        onClick={() => {
                                            setWorldActive(!worldActive)
                                        }}
                                    >
                                        <PublicIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        {
                                            worldActive ?
                                                <CircleIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                            :
                                                <CircleOutlinedIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                            !alwaysActive ?
                                <Grid className={wzVerticalSpace1}/>
                            : null
                        }

                        {
                            !alwaysActive ?
                                <Grid container direction="row">
                                    <Grid container direction="row" alignItems="center" justifyContent="flex-start" style={{ flex: 1, width: 'auto' }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DesktopDatePicker
                                                label="Custom input"
                                                value={startDate}
                                                onChange={(newValue) => {
                                                    setStartDate(newValue)
                                                }}
                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className={wzTextNormal} style={{ marginTop: 0, marginBottom: 0 }}>{getPrettyDate(startDate.getDate())} de {getMonthName(startDate.getMonth())} de {startDate.getFullYear()}</p>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <Grid className={wzHorizontalSpace1}/>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Grid className={wzHorizontalSpace1}/>

                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                            <DesktopDatePicker
                                                label="Custom input"
                                                value={endDate}
                                                onChange={(newValue) => {
                                                    setEndDate(newValue)
                                                }}
                                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <p className={wzTextNormal} style={{ marginTop: 0, marginBottom: 0 }}>{getPrettyDate(endDate.getDate())} de {getMonthName(endDate.getMonth())} de {endDate.getFullYear()}</p>
                                                        {InputProps?.endAdornment}
                                                    </Box>
                                                )}
                                            />
                                        </LocalizationProvider>

                                        <Grid className={wzHorizontalSpace1}/>
                                        <Grid className={wzHorizontalSpace1}/>
                                    </Grid>
                                </Grid>
                            : null
                        }

                        <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra, textAlign: 'center' }}>Escribe tu búsqueda:</p>
                        <Grid container direction="row">
                            <Button
                                className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                onClick={() => {
                                    if(typeSearch == 'Nombre'){
                                        setTypeSearch('País')
                                        doGetUsers('pais')
                                    }else if(typeSearch == 'País'){
                                        setTypeSearch('email')
                                        doGetUsers('email')
                                    }else{
                                        setTypeSearch('Nombre')
                                        doGetUsers('nombre')
                                    }
                                }}
                            >
                                {
                                    typeSearch == 'Nombre' ?
                                        <FaceIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                    :
                                        typeSearch == 'País' ?
                                            <PublicIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        :
                                            <AlternateEmailIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                }
                                <FindReplaceIcon className={wzButtonMiniIcon} style={{ color: 'rgba(255, 255, 255, 0.8)' }} />
                            </Button>

                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>
                            
                            <TextField
                                className={wzTextInput}
                                style={{ flex: 1, width: 'auto' }}
                                label={"Pablo Nájera | ejemplo@ejemplo.com | México"}
                                variant="outlined"
                                type="default"
                                id="custom-css-outlined-input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                inputProps={{
                                    className: wzTextNormal,
                                    onKeyPress: event => {
                                        const { key } = event;
                                        //console.log(key);
                                        if (key === "Enter") {
                                            doGetUsers(getTypeOrderServer(typeSearch))
                                        }
                                    }
                                }}
                            />

                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>

                            <IconButton
                                className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                onClick={() => {
                                    doGetUsers(getTypeOrderServer(typeSearch))
                                }}
                            >
                                <TravelExploreIcon className={wzButtonIcon} style={{ color: 'white' }} />
                            </IconButton>

                            <Grid className={wzHorizontalSpace1}/>
                            <IconButton
                                className={`${wzButtonCirclePadding} ${wzBgExtra}`}
                                onClick={() => {
                                    clearSearch()
                                }}
                            >
                                <ClearIcon className={wzButtonIcon} style={{ color: 'white' }} />
                            </IconButton>
                        </Grid>

                        <p className={wzTextMedium} style={{ color: WzColors.hexPrimary, marginBottom: 0, textAlign: 'center' }}>Ordenado por: <span style={{ fontWeight: 'bold' }}>{typeSearch}</span></p>
                    </Grid>
                </Grid>

                {/*PHONE VIEW*/}
                <Grid container justifyContent="center" sx={{ display: { sm: 'none', xs: 'flex' } }}>
                    <Grid className={`${wzCardContainerRounded} ${wzPadding1} ${wzContainerResponsiveWidth60}`} style={{ position: 'relative' }}>
                        <p className={wzTextNormalHeader} style={{ textAlign: 'center', marginTop: 0, fontWeight: '600' }}>- Buscador -</p>

                        <Grid container direction="row" alignItems="center" justifyContent="space-between">
                            <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Rango de fechas:</p>
                            <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                    <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Siempre:</p>
                                    
                                    <Grid className={wzHorizontalSpace1}/>

                                    <Button
                                        className={`${wzButtonCirclePadding} ${alwaysActive ? wzBgPrimary : wzBgDesPrimary}`}
                                        onClick={() => {
                                            setAlwaysActive(!alwaysActive)
                                        }}
                                    >
                                        <AllInclusiveIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        {
                                            alwaysActive ?
                                                <CircleIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                            :
                                                <CircleOutlinedIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                        }
                                    </Button>
                                </Grid>
                                
                                <Grid className={wzHorizontalSpace1}/>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Grid className={wzHorizontalSpace1}/>

                                <Grid container direction="row" alignItems="center" justifyContent="flex-end" style={{ width: 'auto' }}>
                                    <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra }}>Mundo:</p>
                                    
                                    <Grid className={wzHorizontalSpace1}/>

                                    <Button
                                        className={`${wzButtonCirclePadding} ${worldActive ? wzBgPrimary : wzBgDesPrimary}`}
                                        onClick={() => {
                                            setWorldActive(!worldActive)
                                        }}
                                    >
                                        <PublicIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        {
                                            worldActive ?
                                                <CircleIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                            :
                                                <CircleOutlinedIcon className={wzButtonMiniIcon} style={{ color: 'white' }} />
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                            !alwaysActive ?
                                <Grid className={wzVerticalSpace1}/>
                            : null
                        }
                        
                        {
                            !alwaysActive ?
                                <Grid container direction="row">
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                        <DesktopDatePicker
                                            label="Custom input"
                                            value={startDate}
                                            onChange={(newValue) => {
                                                setStartDate(newValue)
                                            }}
                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <p className={wzTextNormal} style={{ marginTop: 0, marginBottom: 0 }}>{getPrettyDate(startDate.getDate())} de {getMonthName(startDate.getMonth())} de {startDate.getFullYear()}</p>
                                                    {InputProps?.endAdornment}
                                                </Box>
                                            )}
                                        />
                                    </LocalizationProvider>

                                    <Grid className={wzHorizontalSpace1}/>
                                    <Divider orientation="vertical" variant="middle" flexItem />
                                    <Grid className={wzHorizontalSpace1}/>

                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                        <DesktopDatePicker
                                            label="Custom input"
                                            value={endDate}
                                            onChange={(newValue) => {
                                                setEndDate(newValue)
                                            }}
                                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <p className={wzTextNormal} style={{ marginTop: 0, marginBottom: 0 }}>{getPrettyDate(endDate.getDate())} de {getMonthName(endDate.getMonth())} de {endDate.getFullYear()}</p>
                                                    {InputProps?.endAdornment}
                                                </Box>
                                            )}
                                        />
                                    </LocalizationProvider>

                                    <Grid className={wzHorizontalSpace1}/>
                                    <Grid className={wzHorizontalSpace1}/>
                                </Grid>
                            : null
                        }

                        <p className={wzTextSmall} style={{ color: WzColors.hexDesExtra, textAlign: 'center' }}>Escribe tu búsqueda:</p>
                        <Grid container direction="row">
                            <TextField
                                className={wzTextInput}
                                style={{ flex: 1, width: 'auto' }}
                                label={"Pablo Nájera | ejemplo@ejemplo.com | México"}
                                variant="outlined"
                                type="default"
                                id="custom-css-outlined-input"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                inputProps={{
                                    className: wzTextNormal,
                                    onKeyPress: event => {
                                        const { key } = event;
                                        //console.log(key);
                                        if (key === "Enter") {
                                            doGetUsers(getTypeOrderServer(typeSearch))
                                        }
                                    }
                                }}
                            />

                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>

                            <Button
                                className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                onClick={() => {
                                    if(typeSearch == 'Nombre'){
                                        setTypeSearch('País')
                                        doGetUsers('pais')
                                    }else if(typeSearch == 'País'){
                                        setTypeSearch('email')
                                        doGetUsers('email')
                                    }else{
                                        setTypeSearch('Nombre')
                                        doGetUsers('nombre')
                                    }
                                }}
                            >
                                {
                                    typeSearch == 'Nombre' ?
                                        <FaceIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                    :
                                        typeSearch == 'País' ?
                                            <PublicIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        :
                                            <AlternateEmailIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                }
                                <FindReplaceIcon className={wzButtonMiniIcon} style={{ color: 'rgba(255, 255, 255, 0.8)' }} />
                            </Button>
                        </Grid>

                        <Grid className={wzVerticalSpace1}/>
                        
                        <Grid container direction="row">
                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>

                            <IconButton
                                className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                onClick={() => {
                                    doGetUsers(getTypeOrderServer(typeSearch))
                                }}
                            >
                                <TravelExploreIcon className={wzButtonIcon} style={{ color: 'white' }} />
                            </IconButton>

                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>

                            <IconButton
                                className={`${wzButtonCirclePadding} ${wzBgExtra}`}
                                onClick={() => {
                                    clearSearch()
                                }}
                            >
                                <ClearIcon className={wzButtonIcon} style={{ color: 'white' }} />
                            </IconButton>

                            <Grid className={wzHorizontalSpace1}/>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Grid className={wzHorizontalSpace1}/>
                        </Grid>

                        <p className={wzTextMedium} style={{ color: WzColors.hexPrimary, marginBottom: 0, textAlign: 'center' }}>Ordenado por: <span style={{ fontWeight: 'bold' }}>{typeSearch}</span></p>
                    </Grid>
                </Grid>

                {
                    worldActive ?
                        <Grid className={wzVerticalSpace1}/>
                    : null
                }

                {
                    worldActive ?
                        <Grid container direction="row" alignItems="flex-start" justifyContent="space-evenly">
                            <Grid container direction="column" alignItems="center" justifyContent="center" style={{ width: 'auto' }}>
                                <p className={wzTextNormal} style={{ color: WzColors.hexSecondary, textAlign: 'center' }}>Países: <span style={{ fontWeight: 'bold' }}>{getCountriesFiltered(countriesEachCount, continentSearch).length}</span></p>
                                <Grid className={`${wzCardContainerRounded}`} style={{ position: 'relative', padding: 0 }}>
                                    <Grid container direction="row" className={wzPadding1} style={{ paddingBottom: 0 }}>
                                        <TextField
                                            className={wzTextInput}
                                            style={{ flex: 1, width: 'auto' }}
                                            label={"América | México"}
                                            variant="outlined"
                                            type="default"
                                            id="custom-css-outlined-input"
                                            value={continentSearch}
                                            onChange={(e) => setContinentSearch(e.target.value)}
                                            inputProps={{
                                                className: wzTextNormal,
                                                onKeyPress: event => {
                                                    const { key } = event;
                                                    //console.log(key);
                                                    if (key === "Enter") {
                                                        
                                                    }
                                                }
                                            }}
                                        />

                                        <Grid className={wzHorizontalSpace1}/>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                        <Grid className={wzHorizontalSpace1}/>

                                        <IconButton
                                            className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                            onClick={() => {
                                                doGetUsers(getTypeOrderServer(typeSearch))
                                            }}
                                        >
                                            <SearchIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                        </IconButton>
                                    </Grid>
                                    <TableContainer sx={{ minWidth: 0, maxWidth: 450, maxHeight: 200 }}>
                                        <Table sx={{ minWidth: 100 }} size="small" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Continente</TableCell>
                                                    <TableCell align="right">País</TableCell>
                                                    <TableCell align="right">Usuarios</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <FlatList
                                                    list={getCountriesFiltered(countriesEachCount, continentSearch)}
                                                    renderItem={(item, idx) => {
                                                        //console.log('POS EL TRATAMIENTO: ' + JSON.stringify(item))
                                                        return(
                                                            <TableRow
                                                                key={idx}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">
                                                                    {item.continent}
                                                                </TableCell>
                                                                <TableCell align="right">{item.country}</TableCell>
                                                                <TableCell align="right">{item.users}</TableCell>
                                                            </TableRow>
                                                        )
                                                    }}
                                                    renderWhenEmpty={() => <p style={{ color: WzColors.hexPrimary }}>- No se encontraron resultados -</p>}
                                                />
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                            <Grid container direction="column" alignItems="center" justifyContent="center" style={{ width: 'auto' }}>
                                <p className={wzTextNormal} style={{ color: WzColors.hexSecondary, textAlign: 'center' }}>Certificados completados:</p>
                                <Grid className={`${wzCardContainerRounded} ${wzPadding2} ${wzBgPrimary}`} style={{ position: 'relative', borderWidth: 0 }}>
                                    <p className={wzTextHuge} style={{ color: 'white', marginTop: 0, marginBottom: 0, textAlign: 'center' }}>{certificadosCompletados}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    : null
                }
                

                <Grid className={wzVerticalSpace1}/>
                <Grid className={wzVerticalSpace1}/>

                <p className={wzTextNormal} style={{ color: WzColors.hexSecondary, marginTop: 0, textAlign: 'center' }}>Usuarios encontrados: <span style={{ fontWeight: 'bold' }}>{usersData.length}</span></p>
                <Grid className={`${wzCardContainerRounded}`} style={{ position: 'relative', padding: 0 }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell align="right">País</TableCell>
                                    <TableCell align="right">email</TableCell>
                                    <TableCell align="right">Validado</TableCell>
                                    <TableCell align="right"># Certificados</TableCell>
                                    <TableCell align="right">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <FlatList
                                    list={usersData}
                                    renderItem={(item, idx) => {
                                        //console.log('POS EL TRATAMIENTO: ' + JSON.stringify(item))
                                        return(
                                            <TableRow
                                                key={idx}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {item.nombre}
                                                </TableCell>
                                                <TableCell align="right">{item.pais}</TableCell>
                                                <TableCell align="right">{item.email}</TableCell>
                                                <TableCell align="right">
                                                    {
                                                        item.validado == 1 ?
                                                            <CircleIcon className={wzButtonIcon} style={{ color: WzColors.hexAction }} />
                                                        :
                                                            <CircleOutlinedIcon className={wzButtonIcon} style={{ color: WzColors.hexSecondary }} />
                                                    }
                                                </TableCell>
                                                <TableCell align="right">{item.certificados}</TableCell>
                                                <TableCell align="right">
                                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                                        <Tooltip title="Enviar email">
                                                            <IconButton
                                                                className={`${wzButtonCirclePadding} ${wzBgDesPrimary}`}
                                                                onClick={() => {
                                                                    window.location.href = `mailto:${item.email}`
                                                                }}
                                                            >
                                                                <EmailIcon className={wzButtonIcon} style={{ color: WzColors.hexSecondary }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Reenviar código">
                                                            <IconButton
                                                                className={`${wzButtonCirclePadding} ${wzBgExtra}`}
                                                                style={{ marginLeft: '0px !important', marginRight: '0px !important' }}
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        icon: 'question',
                                                                        title: 'Reenviar código de validación',
                                                                        text: `¿Deseas enviar el código de validación a ${item.nombre}?`,
                                                                        confirmButtonText:
                                                                            'Sí',
                                                                        cancelButtonText:
                                                                            'No',
                                                                        focusCancel: true,
                                                                        showCancelButton: true,
                                                                        allowOutsideClick: false,
                                                                        allowEscapeKey: false
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            doSendVerificationCode(item)
                                                                        }
                                                                    })
                                                                }}
                                                            >
                                                                <PasswordIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Ver detalles">
                                                            <IconButton
                                                                className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                                                style={{ marginLeft: '0px !important', marginRight: '0px !important' }}
                                                                onClick={() => {
                                                                    setModalUserDetails(true)
                                                                    setUserDetails(item)
                                                                    setUserDetailsType(parseInt(item.admin))
                                                                    console.log('EL USUARIO: ' + JSON.stringify(item))
                                                                }}
                                                            >
                                                                <AssignmentIndIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }}
                                    renderWhenEmpty={() => <p style={{ color: WzColors.hexPrimary }}>- No se encontraron resultados -</p>}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>
            <Grid className={wzVerticalSpace1}/>

            <Grid container className={wzButtonBottom} style={{ background: 'rgba(255, 255, 255, 0.9)', zIndex: 20, alignItems: 'center', justifyContent: 'space-between', textAlign: 'center'}}>
                <Tooltip title="Ir a administradores">
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate(`/Administradores`, { replace: false, state: {} });
                        }}
                        color="primary"
                        className={`${wzButtonBack} ${wzBgPrimary}`}
                        fullWidth={false}
                    >
                        <ManageAccountsIcon className={wzButtonIcon} style={{ color: 'white' }} />
                    </Button>
                </Tooltip>
                
                <p className={wzTextNormalHeader} style={{ textAlign: 'center' }}>Usuarios registrados: {usersCount}</p>
                
                <Tooltip title="Cerrar sesión">
                    <Button
                        variant="contained"
                        onClick={() => {
                            setToken('')
                            setUserData('{}')
                            navigate(`/`, { replace: true, state: {} });
                        }}
                        color="primary"
                        className={`${wzButtonBackRight} ${wzBgExtra}`}
                        fullWidth={false}
                    >
                        <LogoutIcon className={wzButtonIcon} style={{ color: 'white' }} />
                    </Button>
                </Tooltip>
            </Grid>

            {
                modalUserDetails ?
                    <Grid className={wzFormBackgroundContainer} style={{ zIndex: 2001, overflowY: 'auto', background: WzColors.tSecondary + ' 0.6)' }}>
                        <Grid className={wzFormContainer}>
                            <Grid className={wzFormHeaderContainer} style={{ background: WzColors.hexPrimary }}>
                                <Grid className={wzVerticalSpace1}/>
                                
                                <p className={wzTextNormalHeader} style={{ marginTop: 0, marginBottom: 0, textAlign: 'center', color: 'white', fontWeight: '600' }}>Detalles de usuario</p>
                                
                                <Grid className={wzVerticalSpace1}/>
                                <IconButton
                                    className={`${wzButtonCirclePadding} ${wzBgPrimary} ${wzButtonClose}`}
                                    onClick={() => {
                                        setModalUserDetails(false)
                                    }}
                                >
                                    <ClearIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                </IconButton>
                            </Grid>
                            <Grid container className={`${wzFormContentContainer} ${wzPadding2}`} style={{ paddingTop: 0 }}>
                                <Grid className={wzGroupContainer} style={{ marginTop: 0, paddingTop: 0, zIndex: 100 }}>
                                    <Grid container className={wzPadding1} style={{alignItems: 'center', justifyContent: 'center'}}>
                                        <img src={userDetails.foto} alt="Foto perfil" className={wzProfileImage}/>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{ margin: 0 }}>
                                        <p className={wzTextSmall} style={{ flex: 1 }}>Nombre: <span className={wzTextNormalHeader} style={{ fontWeight: '600' }}>{userDetails.nombre}</span></p>
                                        <Button
                                            className={`${wzButtonCirclePadding} ${wzBgPrimary}`}
                                            onClick={() => {
                                                
                                            }}
                                            style={{ textTransform: 'none' }}
                                            disabled={false}
                                        >
                                            <p className={wzTextSmall} style={{ color: 'white', marginTop: 0, marginBottom: 0 }}>Creado: <span className={wzTextNormalHeader} style={{ fontWeight: '600', color: 'white' }}>{userDetails.created_at}</span></p>
                                        </Button>
                                    </Grid>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextNormal}>{userDetails.email}</p>
                                    </Grid>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextSmall}>Certificados: <span className={wzTextNormalHeader} style={{ fontWeight: '600' }}>{userDetails.certificados}</span></p>
                                    </Grid>
                                </Grid>
                                
                                <Grid className={wzGroupContainer} style={{ zIndex: 99 }}>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextSmall}>Celular: <span className={wzTextNormalHeader} style={{ fontWeight: '600' }}>{userDetails.telefono}</span></p>
                                    </Grid>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextSmall}>País: <span className={wzTextNormalHeader} style={{ fontWeight: '600' }}>{userDetails.pais}</span></p>
                                    </Grid>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextSmall}>Ciudad: <span className={wzTextNormalHeader} style={{ fontWeight: '600' }}>{userDetails.ciudad}</span></p>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" style={{ margin: 0 }}>
                                        <p className={wzTextSmall}>Validado: </p>

                                        <Grid className={wzHorizontalSpace1}/>
                                        
                                        {
                                            userDetails.validado == 1 ?
                                                <CircleIcon className={wzButtonIcon} style={{ color: WzColors.hexAction }} />
                                            :
                                                <CircleOutlinedIcon className={wzButtonIcon} style={{ color: WzColors.hexSecondary }} />
                                        }
                                    </Grid>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextSmall}>Dirección: <span className={wzTextNormalHeader} style={{ fontWeight: '600' }}>{userDetails.direccion}</span></p>
                                    </Grid>
                                </Grid>

                                <Grid className={wzGroupContainer} style={{ zIndex: 98 }}>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextNormalHeader} style={{ fontWeight: '600', marginTop: 0, marginBottom: 0, textAlign: 'center', flex: 1 }}>Progreso:</p>
                                    </Grid>
                                    <FlatList
                                        list={userDetails.num_porc}
                                        renderItem={(item, idx) => {
                                            //console.log('POS EL TRATAMIENTO: ' + JSON.stringify(item))
                                            if(idx == 0){
                                                return(
                                                    <Grid container style={{ margin: 0 }}>
                                                        <p className={wzTextNormal}>Apocalipsis:</p>
                                                        <p className={wzTextNormal} style={{ flex: 1, textAlign: 'right', fontWeight: '600' }}>{Math.ceil((item.real_porc/100)*18)}/18</p>
                                                        <Box sx={{ width: '100%' }}>
                                                            <PrimaryLinearProgress variant="determinate" value={item.real_porc} />
                                                        </Box>
                                                    </Grid>
                                                )
                                            }else if(idx == 1){
                                                return(
                                                    <Grid container style={{ margin: 0 }}>
                                                        <p className={wzTextNormal}>A los pies de Jesús:</p>
                                                        <p className={wzTextNormal} style={{ flex: 1, textAlign: 'right', fontWeight: '600' }}>{Math.ceil((item.real_porc/100)*30)}/30</p>
                                                        <Box sx={{ width: '100%' }}>
                                                            <ActionLinearProgress variant="determinate" value={item.real_porc} />
                                                        </Box>
                                                    </Grid>
                                                )
                                            }
                                        }}
                                        renderWhenEmpty={() => <p style={{ color: WzColors.hexPrimary }}>- No hay progreso -</p>}
                                    />
                                </Grid>

                                <Grid className={wzGroupContainer} style={{ zIndex: 97 }}>
                                    <Grid container style={{ margin: 0 }}>
                                        <p className={wzTextSmall}>Tipo de usuario:</p>
                                    </Grid>
                                    <Grid container direction="row" alignItems="center" justifyContent="center">
                                        <Tooltip title="Administrador principal">
                                            <IconButton
                                                className={`${wzButtonCirclePadding} ${userDetailsType == 1 ? wzBgPrimary : wzBgDesPrimary}`}
                                                onClick={() => {
                                                    if(userData().user_id != userDetails.user_id){
                                                        if(userDetailsType != 1){
                                                            setUserDetailsType(1)
                                                            doUpdateUserType(1)
                                                        }
                                                    }
                                                }}
                                            >
                                                <AdminPanelSettingsIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Administrador colaborador">
                                            <IconButton
                                                className={`${wzButtonCirclePadding} ${userDetailsType == 2 ? wzBgPrimary : wzBgDesPrimary}`}
                                                style={{ marginLeft: '0px !important', marginRight: '0px !important' }}
                                                onClick={() => {
                                                    if(userData().user_id != userDetails.user_id){
                                                        if(userDetailsType != 2){
                                                            setUserDetailsType(2)
                                                            doUpdateUserType(2)
                                                        }
                                                    }
                                                }}
                                            >
                                                <AssignmentIndIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Usuario normal">
                                            <IconButton
                                                className={`${wzButtonCirclePadding} ${userDetailsType == 0 ? wzBgPrimary : wzBgDesPrimary}`}
                                                style={{ marginLeft: '0px !important', marginRight: '0px !important' }}
                                                onClick={() => {
                                                    if(userData().user_id != userDetails.user_id){
                                                        if(userDetailsType != 0){
                                                            setUserDetailsType(0)
                                                            doUpdateUserType(0)
                                                        }
                                                    }
                                                }}
                                            >
                                                <FaceIcon className={wzButtonIcon} style={{ color: 'white' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                : null
            }

            {
                refreshing != '' ?
                    <Grid className={wzWaitingContianer}>
                        <p className={wzTextHuge} style={{color: 'white', fontWeight: 'bold', textAlign: 'center'}}>{refreshing}</p>
                    </Grid>
                :
                    null
            }
        </Grid>
    );
}

export default Home;